<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        title="审核"
        :visible="auditvisible"
        @ok="onauditsub"
        @cancel="onauditcancel"
      >
        <a-form-model-item label="备注">
          <a-input placeholder="请输入备注" v-model="submitNote" />
        </a-form-model-item>
        <a-upload
          action=""
          :multiple="true"
          :file-list="submitAttach"
          :customRequest="handleauditinfo"
          @change="handleauditinfos"
        >
          <a-button> <a-icon type="upload" /> 点击上传 </a-button>
        </a-upload>
      </a-modal>
      <a-modal
        :visible="visrecord"
        title="审核进度"
        @ok="oncanrecord"
        @cancel="oncanrecord"
      >
        <a-timeline>
          <a-timeline-item
            v-for="(item, index) in progresslist"
            :key="index"
            :color="
              item.operate == 1
                ? 'blue'
                : item.operate == 2
                ? 'green'
                : item.operate == 3
                ? 'red'
                : 'blue'
            "
          >
            {{ item.positionName }}审批
            <span v-show="item.auditorName != null"
              >{{ item.auditorName }}&nbsp;</span
            >
            <span v-show="item.auditorTime != null"
              >{{ item.auditorTime | time }}&nbsp;</span
            >
            <span>{{
              item.operate == 2 ? '通过' : item.operate == 3 ? '拒绝' : '待审批'
            }}</span>
          </a-timeline-item>
        </a-timeline>
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>招商实施</a-breadcrumb-item>
            <a-breadcrumb-item>合同管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-button
            v-show="permissionlist.indexOf('/business/sign:新增') > -1"
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增合同
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          min-width: 1600px;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="foms"
          @submit="handleSearchs"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '状态'"
                  v-model="params['cs.state'].value"
                  placeholder="请选择状态"
                  style="width: 178px"
                >
                  <a-select-option value="0"> 未提交 </a-select-option>
                  <a-select-option value="1"> 已提交 </a-select-option>
                  <a-select-option value="2"> 生效中 </a-select-option>
                  <a-select-option value="4"> 撤回中 </a-select-option>
                  <a-select-option value="5"> 作废中 </a-select-option>
                  <a-select-option value="6"> 已作废 </a-select-option>
                  <a-select-option value="7"> 变更中 </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '负责人'"
                  placeholder="请选择负责人"
                  style="width: 178px"
                  v-model="params['cub.id'].value"
                >
                  <a-select-option v-for="item in userlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '签约人'"
                  placeholder="请选择签约人"
                  style="width: 178px"
                  v-model="params['cus.id'].value"
                >
                  <a-select-option v-for="item in userlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-date-picker
                  style="width: 178px"
                  v-else-if="i == '起止时间'"
                  @change="onstartend"
                />
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        style="background: #fff; padding: 24px; min-width: 1600px; margin: 0"
      >
        <a-modal
          title="选择模板"
          :visible="tempvisible"
          :confirm-loading="confirmLoading"
          @ok="handletempOk"
          @cancel="handletempCancel"
        >
          <a-form-model-item label="模板类型">
            <a-select
              style="width: 100%"
              placeholder="请选择模板"
              :filter-option="filterOption"
              v-model="templateId"
            >
              <a-select-option v-for="it in templist" :key="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-modal>
        <!-- 表格 -->
        <div class="search-wrap">
          <a-tabs default-active-key="1" @change="callbacks">
            <a-tab-pane key="1" tab="合同列表">
              <a-table
                rowKey="id"
                :pagination="false"
                :columns="columns"
                :data-source="signslist"
                :rowClassName="rowClassName"
              >
                <a slot="stores" slot-scope="text">
                  <a-popover title="铺位列表">
                    <template slot="content">
                      <p class="text-store" v-for="item in text" :key="item.id">
                        {{ item.buildingNameSnapshot }}&nbsp;
                        {{ item.floorNameSnapshot }}&nbsp;
                        {{ item.storeNameSnapshot }}
                      </p>
                    </template>
                    <span>
                      {{ text[0].buildingNameSnapshot }}
                      {{ text[0].floorNameSnapshot }}
                      {{ text[0].storeNameSnapshot }}
                    </span>
                  </a-popover>
                </a>
                <span slot="time" slot-scope="text, record">
                  {{ record.startDate }}--{{ record.endDate }}
                </span>
                <span slot="status" slot-scope="text">
                  <a-badge
                    :status="text | statusTypeFilter"
                    :text="text | statusFilter"
                  />
                </span>
                <template slot="brandNames" slot-scope="text">
                  <span v-if="text == null || text == ''">
                    <span>{{ text }}</span>
                  </span>
                  <span v-else>
                    <span v-if="text.length <= 9">{{ text }}</span>
                    <a-tooltip v-else>
                      <template slot="title">
                        {{ text }}
                      </template>
                      {{ text.substr(0, 9) + '..' }}
                    </a-tooltip>
                  </span>
                </template>
                <!-- <span slot="brandName" slot-scope="text, record">
                  <div v-if="record.state == 0 || record.state == 1">
                    <a-tooltip v-if="record.brandNameChange.length > 8">
                      <template slot="title">
                        {{ record.brandNameChange }}
                      </template>
                      {{ record.brandNameChange.substr(0, 8) + ".." }}
                    </a-tooltip>
                    <span v-else>{{ record.brandNameChange | text }}</span>
                  </div>
                  <div v-else>
                    <a-tooltip v-if="record.brandName.length > 8">
                      <template slot="title">
                        {{ record.brandName }}
                      </template>
                      {{ record.brandName.substr(0, 8) + ".." }}
                    </a-tooltip>
                    <span v-else>{{ record.brandName | text }}</span>
                  </div>
                </span> -->
                <template slot="partyBName" slot-scope="text, record">
                  <span
                    v-if="record.partyBName == null || record.partyBName == ''"
                  >
                    <span v-if="record.partyBType == 0">{{
                      record.partyBNameE | text
                    }}</span>
                    <span v-else-if="record.partyBType == 1">{{
                      record.partyBNameP | text
                    }}</span>
                  </span>
                  <span v-else>
                    <span v-if="record.partyBName.length <= 8">{{
                      record.partyBName | text
                    }}</span>
                    <a-tooltip v-else>
                      <template slot="title">
                        {{ record.partyBName }}
                      </template>
                      {{ record.partyBName.substr(0, 8) + '..' }}
                    </a-tooltip>
                  </span>
                </template>
                <span slot="partyBType" slot-scope="text">
                  {{ text == 0 ? '公司' : '个人' }}
                </span>
                <span slot="partyAName" slot-scope="text">
                  <span v-if="text.length <= 10">{{ text }}</span>
                  <a-tooltip v-else>
                    <template slot="title">
                      {{ text }}
                    </template>
                    {{ text.length == 0 ? ' ' : text.substr(0, 10) + '..' }}
                  </a-tooltip>
                </span>
                <template slot="action" slot-scope="text, record">
                  <a-dropdown>
                    <a
                      v-show="
                        record.state != 6 &&
                        permissionlist.indexOf('/business/sign:修改') > -1
                      "
                      @click="e => e.preventDefault()"
                    >
                      操作&nbsp;
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item v-show="record.state == 0">
                        <a @click="onEdit(record)" href="javascript:;">{{
                          record.state == 0 ? '编辑' : ''
                        }}</a>
                      </a-menu-item>
                      <a-menu-item v-show="record.state == 0">
                        <a
                          @click.prevent="onchangestate(record, 0)"
                          href="javascript:;"
                          >提交</a
                        >
                      </a-menu-item>
                      <a-menu-item v-show="record.state == 0">
                        <a
                          @click.prevent="showConfirm(record.id)"
                          href="javascript:;"
                          >删除</a
                        >
                      </a-menu-item>
                      <a-menu-item v-show="record.state == 2">
                        <a
                          @click.prevent="onchangestate(record, 5)"
                          href="javascript:;"
                          >撤回</a
                        >
                      </a-menu-item>
                      <a-menu-item v-show="record.state == 2">
                        <a @click.prevent="onend(record, 9)" href="javascript:;"
                          >终止</a
                        >
                      </a-menu-item>
                      <a-menu-item
                        v-show="record.state == 2 || record.state == 2"
                      >
                        <a
                          @click.prevent="onchangestate(record, 1)"
                          href="javascript:;"
                          >作废</a
                        >
                      </a-menu-item>
                      <a-menu-item v-show="record.state == 2">
                        <a
                          @click.prevent="onchanges(record, 1)"
                          href="javascript:;"
                          >变更</a
                        >
                      </a-menu-item>
                      <a-menu-item
                        v-show="
                          record.state == 1 ||
                          record.state == 4 ||
                          record.state == 5 ||
                          record.state == 7 ||
                          record.state == 8 ||
                          record.state == 9
                        "
                      >
                        <a
                          @click.prevent="onchangestate(record, 4)"
                          href="javascript:;"
                          >{{
                            record.state == 1
                              ? '撤销提交'
                              : record.state == 4
                              ? '撤销'
                              : record.state == 5
                              ? '撤销作废'
                              : record.state == 7
                              ? '撤销变更'
                              : record.state == 8
                              ? '撤销变更'
                              : record.state == 9
                              ? '撤销终止'
                              : ' '
                          }}</a
                        >
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <a
                    v-show="
                      record.state == 0 &&
                      permissionlist.indexOf('/business/intention:修改') > -1
                    "
                    @click="ontemp(record.id)"
                    >生成合同&nbsp;&nbsp;</a
                  >
                  <a
                    v-show="
                      record.state == 0 &&
                      permissionlist.indexOf('/business/sign:修改') > -1
                    "
                    @click="ondata(record)"
                    >上传合同&nbsp;&nbsp;</a
                  >
                  <a
                    v-show="
                      permissionlist.indexOf('/business/sign:修改') > -1 &&
                      record.state == 0
                    "
                    @click="oncontract(record)"
                    >补充资料&nbsp;&nbsp;</a
                  >
                  <a v-show="record.state != 0" @click="ondata(record)"
                    >查看合同&nbsp;&nbsp;</a
                  >
                  <a v-show="record.state != 0" @click="oncontract(record)"
                    >查看资料&nbsp;&nbsp;</a
                  >
                  <a
                    v-show="
                      record.state != 0 &&
                      record.state != 2 &&
                      record.state != 3 &&
                      record.state != 6
                    "
                    @click="onrecords(record)"
                    >审核进度&nbsp;&nbsp;</a
                  >
                  <a
                    v-show="
                      permissionlist.indexOf('/business/sign:查询') > -1 ||
                      permissionlist.indexOf('/business/sign:查询全部') > -1
                    "
                    @click="onlook(record)"
                    >详情&nbsp;&nbsp;</a
                  >
                  <a
                    v-show="
                      record.state == 2 &&
                      permissionlist.indexOf('/business/sign:修改') > -1
                    "
                    @click="onrelet(record.id, 3)"
                    >续租</a
                  >
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="草稿箱" force-render>
              <a-table
                rowKey="id"
                :pagination="false"
                :columns="column"
                :data-source="signstermlist"
                :rowClassName="rowClassName"
              >
                <span slot="time" slot-scope="text">
                  {{ text | time }}
                </span>
                <template slot="action" slot-scope="text, record">
                  <a @click="onEdit(record, 11)">编辑 </a> &nbsp;
                  <a-popconfirm
                    title="你确认要删除该合同么?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="onDetele(record)"
                    @cancel="cancel"
                  >
                    <a>删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
        </div>
        <a-drawer
          :width="400"
          :title="title"
          placement="right"
          :closable="false"
          :visible="visibless"
          @close="Close"
        >
          <a-upload-dragger
            v-show="showsub == 0"
            :multiple="true"
            :disabled="lookdisabled"
            :file-list="agreement"
            action=""
            :customRequest="handlefile"
            @preview="handlePreview"
            @change="handlefiles"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击上传</p>
            <p class="ant-upload-hint">
              支持单个或批量上传。严格禁止 上传公司数据或其他波段文件
            </p>
          </a-upload-dragger>
          <a-upload-dragger
            v-show="showsub == 1"
            :multiple="true"
            :file-list="files"
            action=""
            :customRequest="handleChangeinfo"
            @preview="handlePreview"
            @change="handleChangeinfos"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">点击上传</p>
            <p class="ant-upload-hint">
              支持单个或批量上传。严格禁止 上传公司数据或其他波段文件
            </p>
          </a-upload-dragger>
          <div class="save-draw">
            <a-button type="primary" :disabled="lookdisabled" @click="subdata">
              提交
            </a-button>
            <a-button @click="Close" style="margin-left: 30px"> 取消 </a-button>
          </div>
        </a-drawer>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout
      v-else
      style="padding: 24px; height: 100%; overflow: auto; min-width: 1300px"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-layout-content
          :style="{
            background: '#fff',
            margin: 0,
          }"
        >
          <div class="content-title">
            <span>基础信息</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item class="ant--input" label="意向合同">
              <a-select
                :disabled="bodytopdis == 0 ? true : false"
                placeholder="请输入意向合同"
                style="width: 100%"
                v-model="form.intentionId"
                @change="onintent"
              >
                <a-select-option v-for="item in intentionlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="code"
              prop="code"
              class="ant--input"
              label="合同编号"
            >
              <a-input
                :disabled="bodytopdis == 0 ? true : false"
                @blur="
                  () => {
                    $refs.name.onFieldBlur()
                  }
                "
                placeholder="请输入合同编号"
                v-model="form.code"
              />
            </a-form-model-item>
            <a-form-model-item prop="signBy" class="ant--input" label="签约人">
              <a-select
                :disabled="bodytopdis == 0 ? true : false"
                placeholder="请选择签约人"
                style="width: 100%"
                :filter-option="filterOption"
                v-model="form.signBy"
              >
                <a-select-option v-for="item in userlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              prop="belongTo"
              class="ant--input"
              label="负责人"
            >
              <a-select
                :disabled="bodytopdis == 0 ? true : false"
                placeholder="请选择负责人"
                style="width: 100%"
                :filter-option="filterOption"
                v-model="form.belongTo"
              >
                <a-select-option v-for="item in userlist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :validate-status="error1"
              :help="errortext1"
              class="ant--input"
              label="租期起止"
            >
              <a-range-picker
                :disabled="bodytopdis == 0 ? true : false"
                v-model="form.Date"
                @change="onChangetime2"
                :ranges="{
                  '1年': [
                    moment(),
                    moment().add(1, 'years').subtract(1, 'days'),
                  ],
                  '2年': [
                    moment(),
                    moment().add(2, 'years').subtract(1, 'days'),
                  ],
                  '3年': [
                    moment(),
                    moment().add(3, 'years').subtract(1, 'days'),
                  ],
                  '5年': [
                    moment(),
                    moment().add(5, 'years').subtract(1, 'days'),
                  ],
                }"
              >
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item
              prop="signDate"
              class="ant--input"
              label="签约日期"
            >
              <a-date-picker
                :disabled="bodytopdis == 0 ? true : false"
                v-model="form.signDate"
                style="width: 100%"
              >
                <template slot="renderExtraFooter"> </template>
              </a-date-picker>
            </a-form-model-item>
            <a-form-model-item
              prop="giveDate"
              class="ant--input"
              label="交房日期"
            >
              <a-date-picker
                :disabled="bodytopdis == 0 ? true : false"
                v-model="form.giveDate"
                style="width: 100%"
              >
                <template slot="renderExtraFooter"> </template>
              </a-date-picker>
            </a-form-model-item>
            <a-form-model-item
              prop="openDate"
              class="ant--input"
              label="开业日期"
            >
              <a-date-picker
                :disabled="bodytopdis == 0 ? true : false"
                v-model="form.openDate"
                style="width: 100%"
              >
                <template slot="renderExtraFooter"> </template>
              </a-date-picker>
            </a-form-model-item>
          </div>
        </a-layout-content>
        <!-- 合同主体 -->
        <a-layout-content
          style="background: #fff; margin: 24px 0; min-height: 290px"
        >
          <div class="content-title">
            <span>合同主体</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item
              prop="partyAId"
              class="ant--input"
              label="出租方"
            >
              <a-select
                :disabled="bodydis == 0 ? true : false"
                show-search
                :filter-option="filterOption"
                @change="onclientA"
                style="width: 100%"
                v-model="form.partyAId"
              >
                <a-select-option v-for="it in Enterpriselist" :key="it.id">
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="partyAName"
              prop="partyAName"
              class="ant--input"
              label="出租方名称"
            >
              <a-input
                :disabled="bodydis == 0 ? true : false"
                placeholder="请输入出租方名称"
                v-model="form.partyAName"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyAContact"
              prop="partyAContact"
              class="ant--input"
              label="出租方联系电话"
            >
              <a-input
                :disabled="bodydis == 0 ? true : false"
                placeholder="请输入出租方联系电话"
                v-model="form.partyAContact"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyAAddress"
              prop="partyAAddress"
              class="ant--input"
              label="出租方联系地址"
            >
              <a-input
                :disabled="bodydis == 0 ? true : false"
                placeholder="请输入出租方联系地址"
                v-model="form.partyAAddress"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyBId"
              prop="partyBId"
              style="width: 16.6%"
              label="承租方"
            >
              <a-select
                :disabled="bodydis == 0 ? true : false"
                show-search
                :filter-option="filterOption"
                @change="onclientB"
                style="width: 100%"
                v-model="form.partyBId"
              >
                <a-select-option v-for="it in Clientlist" :key="it.id">
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="partyBContact"
              prop="partyBContact"
              style="width: 16.6%"
              label="承租方联系电话"
            >
              <a-input
                :disabled="bodydis == 0 ? true : false"
                placeholder="请输入承租方联系电话"
                v-model="form.partyBContact"
              />
            </a-form-model-item>
            <a-form-model-item
              ref="partyBAddress"
              prop="partyBAddress"
              style="width: 16.6%"
              label="承租方联系地址"
            >
              <a-input
                :disabled="bodydis == 0 ? true : false"
                placeholder="请输入承租方联系地址"
                v-model="form.partyBAddress"
              />
            </a-form-model-item>
            <div
              style="flex-wrap: wrap; width: 50.2%"
              class="flex-d-row"
              v-if="clinttype == 1"
            >
              <a-form-model-item
                ref="partyBNameP"
                prop="partyBNameP"
                style="width: 33.3%"
                label="承租方姓名"
              >
                <a-input
                  :disabled="bodydis == 0 ? true : false"
                  placeholder="请输入承租方姓名"
                  v-model="form.partyBNameP"
                />
              </a-form-model-item>
              <a-form-model-item
                :disabled="bodydis == 0 ? true : false"
                style="width: 33.3%"
                label="承租方性别"
                ref="partyBGenderP"
                prop="partyBGenderP"
              >
                <a-select v-model="form.partyBGenderP" style="width: 100%">
                  <a-select-option value="0"> 女 </a-select-option>
                  <a-select-option value="1"> 男 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                ref="partyBIdP"
                prop="partyBIdP"
                style="width: 33.3%"
                label="承租方证件号"
              >
                <a-input
                  :disabled="bodydis == 0 ? true : false"
                  placeholder="请输入承租方证件号"
                  v-model="form.partyBIdP"
                />
              </a-form-model-item>
            </div>
            <div
              style="flex-wrap: wrap; width: 50.2%"
              class="flex-d-row"
              v-else-if="clinttype == 0"
            >
              <a-form-model-item
                ref="partyBNameE"
                prop="partyBNameE"
                style="width: 33.3%"
                label="承租方法人名称"
              >
                <a-input
                  :disabled="bodydis == 0 ? true : false"
                  placeholder="请输入承租方法人名称"
                  v-model="form.partyBNameE"
                />
              </a-form-model-item>
              <a-form-model-item
                ref="partyBPeopleE"
                prop="partyBPeopleE"
                style="width: 33.3%"
                label="承租方法人代表"
              >
                <a-input
                  :disabled="bodydis == 0 ? true : false"
                  placeholder="请输入承租方法人代表"
                  v-model="form.partyBPeopleE"
                />
              </a-form-model-item>
              <a-form-model-item
                ref="partyBCerditE"
                prop="partyBCerditE"
                style="width: 33.3%"
                label="承租方营业执照"
              >
                <a-input
                  :disabled="bodydis == 0 ? true : false"
                  placeholder="请输入承租方营业执照"
                  v-model="form.partyBCerditE"
                />
              </a-form-model-item>
            </div>
          </div>
        </a-layout-content>
        <a-layout-content style="background: #fff; margin: 24px 0">
          <div class="content-title">
            <span>租赁用途</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <a-form-model-item style="width: 25%" label="业态">
              <a-cascader
                :disabled="bodytopdis == 0 ? true : false"
                :field-names="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                style="width: 100%"
                v-model="form.clause.businessTypeIds"
                :options="BusinessTypes"
                placeholder="请选择业态"
                change-on-select
                @change="onChangetype"
              />
            </a-form-model-item>
            <a-form-model-item style="width: 25%" label="品牌">
              <a-select
                :disabled="bodytopdis == 0 ? true : false"
                show-search
                :value="form.clause.customerBrandId"
                placeholder="品牌不能为空"
                style="width: 100%"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="handleChange"
              >
                <a-select-option v-for="d in datalist" :key="d.value">
                  {{ d.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </a-layout-content>
        <!-- 铺位信息 -->
        <a-drawer
          width="600"
          title="添加铺位"
          :placement="right"
          :closable="false"
          :visible="visibles"
          @close="onclose"
        >
          <div class="search-wrop-left">
            <div class="search-left-project">
              <div class="search-left-top">项目</div>
              <div style="margin-top: 10px">
                <div class="back-search">
                  {{ projectName }}
                </div>
              </div>
            </div>
            <div
              style="overflow: auto; overflow-x: hidden"
              class="search-left-build"
            >
              <div class="search-left-top">楼栋</div>
              <div style="margin-top: 10px">
                <div
                  v-for="(t, i) in buildlist"
                  :key="t.id"
                  @click="buildChange(t, i)"
                  :class="isbuild == i ? 'back-search' : 'search-bottom'"
                >
                  {{ t.name }}
                </div>
              </div>
            </div>
            <div
              style="overflow: auto; overflow-x: hidden"
              class="search-left-floor"
            >
              <div class="search-left-top">楼层</div>
              <div style="margin-top: 10px">
                <div
                  v-for="(t, i) in floorlist"
                  :key="t.id"
                  @click="floorChange(t, i)"
                  :class="isfloor == i ? 'back-search' : 'search-bottom'"
                >
                  {{ t.name }}
                </div>
              </div>
            </div>
            <div class="search-left-stoor">
              <div class="search-left-top flex-ju">
                <input
                  class="input-checkBox"
                  type="checkbox"
                  v-model="checkAll"
                />
                &nbsp;&nbsp;铺位
              </div>
              <div style="margin-top: 10px">
                <div
                  v-for="(t, i) in storelist"
                  :key="t.id"
                  @click="storeChange(t, i)"
                  :class="
                    isstore == i
                      ? 'back-search flex-ju'
                      : 'search-bottom flex-ju'
                  "
                >
                  <input
                    :disabled="
                      t.state == 1
                        ? 'disabled'
                        : t.rentState == 1
                        ? 'disabled'
                        : t.locked == 1
                        ? 'disabled'
                        : false
                    "
                    type="checkbox"
                    @click="onchecked(t.id)"
                    class="input-checkBox"
                    :value="t.id"
                    v-model="checkStore"
                  />
                  &nbsp;&nbsp;{{ t.name }}
                </div>
                <!-- <a-checkbox-group
                  v-model="checkedList"
                  :options="storelist"
                  @change="onChange"
                /> -->
              </div>
            </div>
          </div>
          <div style="flex-wrap: wrap" class="flex-d-row">
            <a-form-item class="ant--input" label="建筑面积">
              <a-input
                disabled="disabled"
                placeholder="请输入建筑面积"
                v-model="stores.totalArea"
              />
            </a-form-item>
            <a-form-item class="ant--input" label="使用面积">
              <a-input
                disabled="disabled"
                placeholder="请输入建筑面积"
                v-model="stores.useArea"
              />
            </a-form-item>
            <a-form-item class="ant--input" label="计租面积">
              <a-input
                disabled="disabled"
                placeholder="请输入建筑面积"
                v-model="stores.rentArea"
              />
            </a-form-item>
            <a-form-model-item class="ant--input" label="产权属性">
              <a-select
                disabled="disabled"
                style="width: 100%"
                :filter-option="filterOption"
                placeholder="请选择产权"
                v-model="stores.propertyTypeId"
              >
                <a-select-option v-for="item in typelist" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-item class="price-formitem" label="月标准价格">
              <a-input
                disabled="disabled"
                placeholder="请输入标准价格"
                v-model="stores.monthLowestFee"
              />
              <div class="price">元/m²·月</div>
            </a-form-item>
            <a-form-item class="price-formitem" label="月最低价格">
              <a-input
                disabled="disabled"
                placeholder="请输入最低价格"
                v-model="stores.monthStandardFee"
              />
              <div class="price">元/m²·月</div>
            </a-form-item>
            <a-form-item class="price-formitem" label="日标准价格">
              <a-input
                disabled="disabled"
                placeholder="请输入标准价格"
                v-model="stores.dayLowestFee"
              />
              <div class="price">元/m²·日</div>
            </a-form-item>
            <a-form-item class="price-formitem" label="日最低价格">
              <a-input
                disabled="disabled"
                placeholder="请输入最低价格"
                v-model="stores.dayStandardFee"
              />
              <div class="price">元/m²·日</div>
            </a-form-item>
            <a-form-item style="width: 33%" label="招商类型">
              <a-select
                disabled="disabled"
                v-model="stores.state"
                style="width: 100%"
              >
                <a-select-option value="0"> 启用</a-select-option>
                <a-select-option value="1"> 禁用</a-select-option>
              </a-select>
            </a-form-item>

            <div class="flex-d-row" style="width: 65%" v-if="stores.state == 0">
              <a-form-item style="width: 50%" label="有意人数">
                <a-input
                  disabled="disabled"
                  placeholder="请输入有意人数"
                  v-model="stores.talkDetail"
                />
              </a-form-item>
              <a-form-item
                style="width: 50%"
                class="ant--input"
                label="租赁状态"
              >
                <a-select
                  disabled="disabled"
                  v-model="stores.rentState"
                  style="width: 100%"
                >
                  <a-select-option value="0"> 未租赁</a-select-option>
                  <a-select-option value="1"> 已租赁</a-select-option>
                </a-select>
              </a-form-item>
              <!--  <a-form-item
                style="width:50%"
                v-if="stores.locked == 1"
                class="ant--input"
                label="到期时间"
              >
                <a-input
                  disabled="disabled"
                  placeholder="到期时间"
                  v-model="stores.updateTime"
                />
              </a-form-item> -->
            </div>
          </div>
          <div class="save-draw">
            <a-button
              v-if="
                stores.state == 1 || stores.rentState == 1 || stores.locked == 1
              "
              disabled="disabled"
              type="primary"
            >
              保存
            </a-button>
            <a-button v-else type="primary" @click="handOk"> 保存 </a-button>
            <a-button @click="onclose" style="margin-left: 30px">
              取消
            </a-button>
          </div>
        </a-drawer>
        <a-layout-content
          style="background: #fff; margin: 24px 0; min-height: 200px"
        >
          <div class="content-title">
            <span>铺位信息</span>
          </div>
          <div
            style="overflow: auto; overflow-y: hidden"
            class="content-body flex-d-row"
          >
            <div
              v-for="(item, index) in form.stores"
              :key="item.name"
              style="position: relative"
            >
              <div
                style="line-height: 65px; margin-right: 10px"
                class="body-building flex-d-column"
                @click="Addmobile(item, index)"
              >
                <span>{{ item.storeNameSnapshot }}</span>
              </div>
              <span @click="deletestore(index)" class="deletestore">x</span>
              <div class="deletestore" style="left: 5px; width: 20px">
                <a-icon
                  v-if="item.locked == 1"
                  style="font-size: 11px; z-index: 10"
                  type="lock"
                />
                <a-icon
                  v-else
                  style="font-size: 11px; z-index: 10"
                  type="unlock"
                />
              </div>
            </div>
            <div
              v-show="bodytopdis == 1"
              :disabled="bodytopdis == 0 ? true : false"
              @click="Addmobile(undefined)"
              class="body-building"
            >
              +
            </div>
          </div>
        </a-layout-content>
      </a-form-model>
      <a-form :form="fom" :label-col="{ span: 12 }" :wrapper-col="{ span: 24 }">
        <!--租赁信息 -->
        <a-layout-content style="background: #fff; margin-bottom: 24px">
          <div class="content-title">
            <span>租赁条款</span>
          </div>
          <div style="flex-wrap: wrap" class="content-body flex-d-row">
            <div
              v-for="(item, index) in form.clause.clauseTerm.termDurations"
              :key="item"
              style="position: relative; width: 100%"
              class="flex-d-row"
            >
              <a-form-item style="width: 20%" label="铺位">
                <a-select
                  :disabled="termdis == 0 ? true : false"
                  mode="multiple"
                  placeholder="请输入铺位"
                  style="width: 100%; position: relative; margin-top: 3px"
                  @change="onChangestore(index)"
                  v-model="form.clause.clauseTerm.termDurations[index].store"
                >
                  <a-select-option
                    v-for="item in form.stores"
                    :key="item.storeId"
                  >
                    {{ item.storeNameSnapshot }}
                  </a-select-option>
                </a-select>
                <a-button
                  :disabled="termdis == 0 ? true : false"
                  @click="onAllclick(index)"
                  class="allcheck"
                  type="primary"
                >
                  {{
                    form.clause.clauseTerm.termDurations[index].stores.length ==
                    form.stores.length
                      ? '清除'
                      : '全选'
                  }}
                </a-button>
              </a-form-item>
              <a-form-item
                :validate-status="error"
                :help="errortext"
                class="terms-input"
                label="起止时间"
              >
                <a-range-picker
                  :disabled="termdis == 0 ? true : false"
                  :disabled-date="disabledDate"
                  :disabled-time="disabledRangeTime"
                  style="width: 100%; height: 30px"
                  v-model="form.clause.clauseTerm.termDurations[index].Date"
                  format="YYYY-MM-DD"
                  @change="onChangetime1(index)"
                />
              </a-form-item>
              <a-form-item class="terms-input" label="计租方式">
                <a-select
                  :disabled="termdis == 0 ? true : false"
                  placeholder="请选择计租方式"
                  style="width: 100%"
                  v-model="form.clause.clauseTerm.termDurations[index].type"
                >
                  <a-select-option value="0"> 固定租金 </a-select-option>
                  <a-select-option value="1"> 提成 </a-select-option>
                  <a-select-option value="2"> 固定租金加提成 </a-select-option>
                  <a-select-option value="3">
                    固定租金或提成取高
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                v-show="
                  form.clause.clauseTerm.termDurations[index].type == 0 ||
                  form.clause.clauseTerm.termDurations[index].type == 2 ||
                  form.clause.clauseTerm.termDurations[index].type == 3
                "
                class="terms-input"
                label="租金单价"
              >
                <div style="padding-top: 4px" class="flex-d-row ccc">
                  <a-input-number
                    :disabled="termdis == 0 ? true : false"
                    :min="0"
                    :step="0.01"
                    v-model="form.clause.clauseTerm.termDurations[index].fee"
                    style="border-right: 0px; width: 100%"
                    placeholder="请输入最低价格"
                  />
                  <a-select
                    :disabled="termdis == 0 ? true : false"
                    v-model="
                      form.clause.clauseTerm.termDurations[index].feeType
                    "
                  >
                    <a-select-option value="0"> 元/m².天 </a-select-option>
                    <a-select-option value="5"> 元/m².月 </a-select-option>
                    <a-select-option value="6"> 元/日 </a-select-option>
                    <a-select-option value="1"> 元/月 </a-select-option>
                    <a-select-option value="3"> 元/年 </a-select-option>
                    <a-select-option value="4"> 元/m² </a-select-option>
                    <a-select-option value="2"> 元 </a-select-option>
                  </a-select>
                </div>
              </a-form-item>
              <a-form-item
                v-show="
                  form.clause.clauseTerm.termDurations[index].type == 1 ||
                  form.clause.clauseTerm.termDurations[index].type == 2 ||
                  form.clause.clauseTerm.termDurations[index].type == 3
                "
                class="terms-input"
                label="提成比例"
              >
                <a-input-number
                  :disabled="termdis == 0 ? true : false"
                  style="width: 100%; margin-top: 5px"
                  :min="0"
                  :step="0.01"
                  v-model="form.clause.clauseTerm.termDurations[index].rate"
                  placeholder="请输入最低价格"
                />
                <div style="bottom: -5px" class="price">%</div>
              </a-form-item>
              <div
                v-show="
                  index == form.clause.clauseTerm.termDurations.length - 1 &&
                  permissionlist.indexOf('/business/sign:修改') > -1
                "
                class="terms-option flex-d-row"
              >
                <a-button @click="addDurations(index)" size="small">
                  新增
                </a-button>
                <a-button
                  @click="deleteDurations(index)"
                  type="danger"
                  size="small"
                >
                  删除
                </a-button>
                <!-- <a-icon
                
                style="color:red;font-size: 18px;"
                type="minus-circle"
              />
              <a-icon
                
                style="font-size: 18px;"
                type="plus-circle"
              /> -->
              </div>
            </div>

            <a-form-item style="width: 15%" label="租期划分">
              <a-select
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                v-model="form.clause.clauseTerm.termType"
              >
                <a-select-option value="0"> 起止日划分 </a-select-option>
                <a-select-option value="1"> 自然月划分 </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item style="width: 10%" label="精确度">
              <a-select
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                v-model="form.clause.clauseTerm.zeroType"
              >
                <a-select-option value="0"> 元 </a-select-option>
                <a-select-option value="1"> 角 </a-select-option>
                <a-select-option value="2"> 分 </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item style="width: 15%" label="付款周期">
              <a-input-number
                :min="1"
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                v-model="form.clause.clauseTerm.term"
                placeholder="请输入付款周期"
              />
              <div class="price">月</div>
            </a-form-item>
            <a-form-item style="width: 20%" label="账单日期">
              <div style="padding-top: 4px" class="flex-d-row aaa">
                <a-select v-model="form.clause.clauseTerm.billType">
                  <a-select-option value="0"> 提前 </a-select-option>
                  <a-select-option value="1"> 推后 </a-select-option>
                  <a-select-option value="2"> 日期 </a-select-option>
                </a-select>
                <a-input-number
                  style="width: 100%"
                  :disabled="termdis == 0 ? true : false"
                  :min="1"
                  v-model="form.clause.clauseTerm.billDateNum"
                  placeholder="请输入账单日期"
                />
                <a-select
                  v-model="form.clause.clauseTerm.billType2"
                  v-if="form.clause.clauseTerm.billType == 2"
                >
                  <a-select-option value="0"> 上月 </a-select-option>
                  <a-select-option value="1"> 本月 </a-select-option>
                  <a-select-option value="2"> 下月 </a-select-option>
                </a-select>
                <a-select v-model="form.clause.clauseTerm.billDateType" v-else>
                  <a-select-option value="0"> 自然日 </a-select-option>
                  <a-select-option value="1"> 工作日 </a-select-option>
                </a-select>
              </div>
            </a-form-item>
            <a-form-item style="width: 20%" label="缴费日期">
              <div style="padding-top: 4px" class="flex-d-row aaa">
                <div
                  style="
                    width: 80px;
                    border: 1px solid #d9d9d9;
                    height: 32px;
                    border-right: none;
                    line-height: 32px;
                    text-align: center;
                  "
                >
                  账单日期后
                </div>
                <a-input-number
                  :disabled="termdis == 0 ? true : false"
                  :min="1"
                  v-model="form.clause.clauseTerm.payDateNum"
                  style="flex: 1; border-left: 1px solid #d9d9d9; width: 100%"
                  placeholder="请输入缴费日期"
                />
                <a-select v-model="form.clause.clauseTerm.payDateType">
                  <a-select-option value="0"> 自然日 </a-select-option>
                  <a-select-option value="1"> 工作日 </a-select-option>
                </a-select>
              </div>
            </a-form-item>
            <a-form-item style="width: 20%" label="首期缴费日期">
              <div style="padding-top: 4px" class="flex-d-row bbb">
                <a-select v-model="form.clause.clauseTerm.firstPayType">
                  <a-select-option value="0"> 跟随账单 </a-select-option>
                  <a-select-option value="1"> 指定日期 </a-select-option>
                </a-select>
                <a-date-picker
                  :disabled="termdis == 0 ? true : false"
                  style="width: 100%"
                  v-model="form.clause.clauseTerm.firstPayDate"
                  v-if="form.clause.clauseTerm.firstPayType == 1"
                  @change="onChangetime3"
                  placeholder="请输入首期缴费日期"
                />
                <a-date-picker v-else disabled="disabled" />
              </div>
            </a-form-item>
          </div>
        </a-layout-content>
      </a-form>
      <!-- 管理费条款 -->
      <a-layout-content style="background: #fff">
        <div class="content-title">
          <span>管理费条款</span>
          <!-- <a-icon @click="deleteProperty" type="delete" /> -->
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-item class="ant--input" label="物业管理服务费">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseProperty.propertyFee"
                placeholder="请输入物业管理服务费"
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseProperty.propertyFeeType"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label="综合管理服务费">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseProperty.commonFee"
                placeholder="请输入综合管理服务费"
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseProperty.commonFeeType"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label=" 装修管理服务费 ">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseProperty.decorateFee"
                placeholder="请输入装修管理服务费"
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseProperty.decorateFeeType"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label="运营推广费">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseProperty.adFee"
                placeholder="请输入运营推广费"
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseProperty.adFeeType"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label="公共能耗及物业代办费 ">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseProperty.publicFee"
                placeholder="请输入公共能耗及物业代办费 "
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseProperty.publicFeeType"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label=" 建筑垃圾清运费 ">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseProperty.rubbishFee"
                placeholder="请输入建筑垃圾清运费"
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseProperty.rubbishFeeType"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item style="width: 20%" label="付款周期">
            <div style="padding-top: 4px" class="flex-d-row bbb">
              <a-select v-model="form.clause.clauseProperty.termType">
                <a-select-option value="0"> 跟随账单 </a-select-option>
                <a-select-option value="1">
                  <a-tooltip>
                    <template slot="title">
                      指定周期：管理费的付款周期（单位月）例：指定周期为6，则管理费每6个月付款1次
                    </template>
                    指定周期
                  </a-tooltip>
                </a-select-option>
              </a-select>
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                v-if="form.clause.clauseProperty.termType == 1"
                style="width: 100%"
                :min="0"
                v-model="form.clause.clauseProperty.term"
                placeholder="请输入周期"
              />
              <a-input v-else disabled="disabled"></a-input>
            </div>
          </a-form-item>
        </div>
      </a-layout-content>
      <!-- 滞纳金 -->
      <a-layout-content
        v-show="
          form.clause.clauseLate.lateType == 0 ||
          form.clause.clauseLate.lateType == 1
        "
        class="yiban"
        id="banzheng"
      >
        <div class="content-title">
          <span>滞纳金条款</span>
          <a-icon @click="deleteLate" type="delete" />
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-item class="ant--input" label="起算天数">
            <div style="padding-top: 4px" class="flex-d-row aaa">
              <div
                style="
                  width: 80px;
                  border: 1px solid #d9d9d9;
                  height: 32px;
                  border-right: none;
                  line-height: 32px;
                  text-align: center;
                "
              >
                逾期
              </div>
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                :min="1"
                v-model="form.clause.clauseLate.days"
                style="flex: 1; border-left: 1px solid #d9d9d9; width: 100%"
                placeholder="请输入起算天数"
              />
              <div
                style="
                  width: 80px;
                  border: 1px solid #d9d9d9;
                  height: 32px;
                  line-height: 32px;
                  text-align: center;
                "
              >
                天
              </div>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label="滞纳金">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                :min="0"
                :step="0.01"
                v-model="form.clause.clauseLate.fee"
                style="flex: 1; border-left: 1px solid #d9d9d9; width: 100%"
                placeholder="请输入滞纳金"
              />
              <a-select v-model="form.clause.clauseLate.lateType">
                <a-select-option value="0"> %/天 </a-select-option>
                <a-select-option value="1"> 元/天 </a-select-option>
              </a-select>
            </div>
          </a-form-item>
          <a-form-item class="ant--input" label="滞纳金上限">
            <a-input-number
              :disabled="termdis == 0 ? true : false"
              style="width: 100%"
              :min="0"
              :step="0.01"
              v-model="form.clause.clauseLate.limit"
              placeholder="请输入滞纳金上限"
            />
            <div v-if="form.clause.clauseLate.lateType == 1" class="price">
              元
            </div>
            <div v-else class="price">%</div>
          </a-form-item>
        </div>
      </a-layout-content>
      <!-- 保证金 -->
      <a-layout-content
        v-for="(item, index) in form.clause.clauseSureties"
        :key="item"
        class="yiban"
        id="banzheng"
      >
        <div class="content-title">
          <span>保证金条款</span>
          <a-icon @click="deleteSureties(index)" type="delete" />
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-item prop="type" class="ant--input" label="保证金类型">
            <a-select
              :disabled="termdis == 0 ? true : false"
              placeholder="请选择计租方式"
              style="width: 100%"
              v-model="form.clause.clauseSureties[index].type"
            >
              <a-select-option value="0"> 履约保证金 </a-select-option>
              <a-select-option value="1"> 质量保证金 </a-select-option>
              <a-select-option value="2"> 装修保证金 </a-select-option>
              <a-select-option value="3"> 水电保证金 </a-select-option>
              <a-select-option value="4">
                物业管理服务费保证金
              </a-select-option>
              <a-select-option value="5">
                综合管理服务费保证金
              </a-select-option>
              <a-select-option value="6">
                装修及改造复原保证金
              </a-select-option>
              <a-select-option value="7"> 自定义 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            v-show="form.clause.clauseSureties[index].type == 7"
            class="ant--input"
            label="保证金名称"
          >
            <a-input
              :disabled="termdis == 0 ? true : false"
              v-model="form.clause.clauseSureties[index].name"
              placeholder="请输入保证金名称"
            />
          </a-form-item>
          <a-form-item class="ant--input" label="金额">
            <a-input-number
              :disabled="termdis == 0 ? true : false"
              style="width: 100%"
              :min="0"
              v-model="form.clause.clauseSureties[index].fee"
              placeholder="请输入金额"
            />
            <div class="price">元</div>
          </a-form-item>
          <a-form-item prop=" paydate" class="ant--input" label="付款日期">
            <div style="padding-top: 4px" class="flex-d-row bbb">
              <a-select v-model="form.clause.clauseSureties[index].payDateType">
                <a-select-option value="0"> 跟随账单 </a-select-option>
                <a-select-option value="1"> 指定日期 </a-select-option>
              </a-select>
              <a-date-picker
                v-if="form.clause.clauseSureties[index].payDateType == 0"
                disabled="disabled"
                style="width: 100%"
              />
              <a-date-picker
                v-else
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseSureties[index].payDate"
                style="width: 100%"
                @change="onChangetime4(index)"
              />
            </div>
          </a-form-item>
        </div>
      </a-layout-content>
      <!-- 优惠 -->
      <a-layout-content
        id="youhui"
        v-for="(item, index) in form.clause.clauseDiscounts"
        :key="item.id"
        class="yiban"
      >
        <div class="content-title">
          <span>优惠条款</span>
          <a-icon @click="deleteDiscounts(index)" type="delete" />
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-item prop="type" class="ant--input" label="优惠类型">
            <a-select
              :disabled="termdis == 0 ? true : false"
              placeholder="请选择计租方式"
              style="width: 100%"
              v-model="form.clause.clauseDiscounts[index].type"
            >
              <a-select-option value="0"> 免租期 </a-select-option>
              <a-select-option value="1"> 装修期 </a-select-option>
              <a-select-option value="2"> 单价折扣 </a-select-option>
              <a-select-option value="3"> 单价减免 </a-select-option>
              <a-select-option value="4"> 每期应收减免 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="ant--input" label="优惠">
            <div style="padding-top: 4px" class="flex-d-row ccc">
              <a-input-number
                :disabled="termdis == 0 ? true : false"
                style="width: 100%"
                :min="0"
                v-model="form.clause.clauseDiscounts[index].discount"
                placeholder="请输入优惠"
              />
              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseDiscounts[index].discountType"
                v-if="
                  form.clause.clauseDiscounts[index].type == 0 ||
                  form.clause.clauseDiscounts[index].type == 1
                "
              >
                <a-select-option value="0"> 天 </a-select-option>
                <a-select-option value="1"> 月 </a-select-option>
              </a-select>

              <a-select
                :disabled="termdis == 0 ? true : false"
                v-model="form.clause.clauseDiscounts[index].discountType"
                v-else-if="form.clause.clauseDiscounts[index].type == 3"
              >
                <a-select-option value="0"> 元/m².天 </a-select-option>
                <a-select-option value="5"> 元/m².月 </a-select-option>
                <a-select-option value="6"> 元/日 </a-select-option>
                <a-select-option value="1"> 元/月 </a-select-option>
                <a-select-option value="3"> 元/年 </a-select-option>
                <a-select-option value="4"> 元/m² </a-select-option>
                <a-select-option value="2"> 元 </a-select-option>
              </a-select>
              <div
                style="
                  width: 80px;
                  border: 1px solid #d9d9d9;
                  height: 32px;
                  line-height: 32px;
                  text-align: center;
                "
                v-else
              >
                {{ form.clause.clauseDiscounts[index].type == 2 ? '折' : '元' }}
              </div>
            </div>
          </a-form-item>
          <a-form-item style="width: 12%" label="开始期数">
            <a-input-number
              :disabled="termdis == 0 ? true : false"
              style="width: 100%"
              :min="0"
              v-model="form.clause.clauseDiscounts[index].discountTerm"
              placeholder="请输入开始期数"
            />
          </a-form-item>
          <a-form-item style="width: 13%" label="持续期数">
            <a-input-number
              :disabled="termdis == 0 ? true : false"
              style="width: 100%"
              :min="0"
              v-model="form.clause.clauseDiscounts[index].discountDuration"
              placeholder="请输入持续期数"
            />
          </a-form-item>
          <a-form-item class="ant--input" label="备注">
            <a-input
              :disabled="termdis == 0 ? true : false"
              v-model="form.clause.clauseDiscounts[index].note"
              placeholder="请输入备注"
            />
          </a-form-item>
        </div>
      </a-layout-content>

      <a-modal
        title="账单预览"
        :visible="modelvisible"
        width="1100px"
        @ok="handleOk"
        okText="确定提交"
        :ok-button-props="{
          props: { disabled: termdis == 0 ? true : false },
        }"
        @cancel="handleCancel"
      >
        <a-table
          rowKey="id"
          @expand="onexpand"
          :expandedRowKeys="expandedRowKeys"
          childrenColumnName="child"
          :columns="modelcolumns"
          :data-source="data"
          class="components-table-demo-nested"
        >
          <span slot="startDate" slot-scope="text, record"
            >{{ record.startDate }}-{{ record.endDate }}
          </span>
          <a-table
            rowKey="name"
            slot="expandedRowRender"
            childrenColumnName="child"
            :columns="innerColumns"
            :data-source="innerData"
            :pagination="false"
          >
            <span slot="startDate" slot-scope="text, record"
              >{{ record.startDate }}-{{ record.endDate }}
            </span>
            <span slot="feeType" slot-scope="text, record"
              >{{
                record.feeType == 0
                  ? '元/m²·天'
                  : record.feeType == 1
                  ? '元/月'
                  : record.feeType == 2
                  ? '元'
                  : record.feeType == 3
                  ? '元/年'
                  : record.feeType == 4
                  ? '元/m²'
                  : record.feeType == 5
                  ? '元/m²/月'
                  : '元/日'
              }}
            </span>
          </a-table>
        </a-table>
      </a-modal>
    </a-layout>
    <a-modal
      title="保存草稿"
      :visible="termvisible"
      @ok="subcao"
      @cancel="
        () => {
          this.termvisible = false
        }
      "
    >
      <a-form-item label="草稿名称">
        <a-input placeholder="请输入草稿名称" v-model="form.name" />
      </a-form-item>
    </a-modal>
    <a-modal
      :title="moreact == 9 ? '账单清算预览' : '操作'"
      :visible="morevisible"
      width="800px"
      @ok="onmoreaction"
      @cancel="onmorecancel"
    >
      <a-form-item v-if="moreact == 3" label="到期时间">
        <a-date-picker
          v-model="form.endDate"
          style="width: 100%"
          disabled="disabled"
        />
      </a-form-item>
      <a-form-item v-if="moreact == 3" label="续租时间">
        <div style="padding-top: 4px" class="flex-d-row ccc">
          <a-input
            v-model="letenumb"
            style="flex: 1; border-left: 1px solid #d9d9d9"
            placeholder="请输入续租时间"
          />
          <a-select style="width: 100px" v-model="letetype">
            <a-select-option value="0"> 月 </a-select-option>
            <a-select-option value="1"> 年 </a-select-option>
          </a-select>
        </div>
      </a-form-item>
      <a-form-item v-else-if="moreact == 2" label="作废原因">
        <a-input v-model="disuseNote" placeholder="请输入作废原因" />
      </a-form-item>
      <div v-else-if="moreact == 1">
        <a-button
          style="width: 46.5%; height: 90px"
          @click="changemain(2)"
          type="primary"
        >
          变更主体
        </a-button>
        <a-button style="width: 46.5%; height: 90px" @click="changekuan(3)">
          变更条款</a-button
        >
      </div>
      <a-descriptions
        class="form-item"
        v-else-if="moreact == 9"
        :column="2"
        title=" "
        bordered
      >
        <a-descriptions-item label="账单清算" :span="2">
          <div class="flex-d-row">
            <a-form-model-item class="ant--input" label="账单总金额">
              {{ clearpreview.allfee }}元
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="物业费">
              {{ clearpreview.feeProperty }}元
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="保证金">
              {{ clearpreview.surety }}元
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="优惠折扣">
              {{ clearpreview.discount }}
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="已付金额">
              {{ clearpreview.payedRent }}元
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="待结算金额">
              {{ clearpreview.clear }}元
            </a-form-model-item>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="合同主体" :span="2">
          <div
            style="flex-wrap: wrap; justify-content: space-between"
            class="flex-d-row"
          >
            <a-form-model-item style="width: 32%" label="出租方名称">
              <a-tooltip>
                <template slot="title">
                  {{ form.partyAName }}
                </template>
                {{ form.partyAName | text }}
              </a-tooltip>
            </a-form-model-item>
            <a-form-model-item style="width: 30%" label="出租方联系电话">
              {{ form.partyAContact }}
            </a-form-model-item>
            <a-form-model-item style="width: 30%" label="出租方联系地址">
              <a-tooltip>
                <template slot="title">
                  {{ form.partyAAddress }}
                </template>
                {{ form.partyAAddress | text }}
              </a-tooltip>
            </a-form-model-item>
            <a-form-model-item style="width: 32%" label="承租方名称">
              <a-tooltip>
                <template slot="title">
                  {{ form.partyBName }}
                </template>
                {{ form.partyBName | text }}
              </a-tooltip>
            </a-form-model-item>
            <a-form-model-item style="width: 30%" label="承租方联系电话">
              {{ form.partyBContact }}
            </a-form-model-item>
            <a-form-model-item style="width: 30%" label="承租方联系地址">
              <a-tooltip>
                <template slot="title">
                  {{ form.partyBAddress }}
                </template>
                {{ form.partyBAddress | text }}
              </a-tooltip>
            </a-form-model-item>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="租赁条款" :span="2">
          <div
            style="flex-wrap: wrap; justify-content: space-between"
            class="flex-d-row"
          >
            <a-form-model-item style="width: 32%" label="付款周期">
              {{ form.clause.clauseTerm.term }}
            </a-form-model-item>
            <a-form-model-item style="width: 30%" label="账单日期">
              {{
                form.clause.clauseTerm.billType == 0
                  ? '提前'
                  : form.clause.clauseTerm.billType == 1
                  ? '推后'
                  : '日期'
              }}
              {{ form.clause.clauseTerm.billDateNum
              }}{{
                form.clause.clauseTerm.billDateType == 0
                  ? '自然日'
                  : form.clause.clauseTerm.billDateType == 1
                  ? '工作日'
                  : ''
              }}
            </a-form-model-item>
            <a-form-model-item style="width: 30%" label="缴费日期">
              账单日期后{{ form.clause.clauseTerm.payDateNum
              }}{{
                form.clause.clauseTerm.payDateType == 0 ? '自然日' : '工作日'
              }}
            </a-form-model-item>
          </div>
          <div
            style="flex-wrap: wrap; justify-content: space-between"
            v-for="item in form.clause.clauseTerm.termDurations"
            :key="item.id"
            class="flex-d-row"
          >
            <a-form-model-item label="铺位">
              <a-select
                mode="multiple"
                disabled="disabled"
                placeholder="暂无"
                style="width: 100%; position: relative; margin-top: 3px"
                v-model="item.store"
              >
                <a-select-option v-for="it in form.stores" :key="it.storeId">
                  {{ it.storeNameSnapshot }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账单日期">
              {{ item.startDate }}~{{ item.endDate }}
            </a-form-model-item>
            <a-form-model-item style="min-width: 90px" label="租金单价">
              {{ item.fee == null ? item.rate + '%' : item.fee }}
              <span v-if="item.type == 0 || item.type == 2 || item.type == 3">{{
                item.feeType == 0
                  ? '元/m².天'
                  : item.feeType == 5
                  ? '元/m².月'
                  : item.feeType == 6
                  ? '元/日'
                  : item.feeType == 1
                  ? '元/月'
                  : item.feeType == 3
                  ? '元/年'
                  : item.feeType == 4
                  ? '元/m²'
                  : item.feeType == 2
                  ? '元'
                  : ''
              }}</span>
            </a-form-model-item>
          </div>
        </a-descriptions-item>
        <!-- 管理费 -->
        <a-descriptions-item label="管理费条款" :span="2">
          <div
            style="flex-wrap: wrap; justify-content: space-between"
            class="flex-d-row"
          >
            <a-form-model-item style="width: 33%" label="物业管理费">
              {{
                form.clause.clauseProperty.propertyFee == null
                  ? 0
                  : form.clause.clauseProperty.propertyFee
              }}
            </a-form-model-item>
            <a-form-model-item style="width: 33%" label="综合管理费">
              {{
                form.clause.clauseProperty.commonFee == null
                  ? 0
                  : form.clause.clauseProperty.commonFee
              }}
            </a-form-model-item>
            <a-form-model-item style="width: 33%" label="装修管理费">
              {{
                form.clause.clauseProperty.decorateFee == null
                  ? 0
                  : form.clause.clauseProperty.decorateFee
              }}
            </a-form-model-item>
            <a-form-model-item style="width: 33%" label="运营推广费">
              {{
                form.clause.clauseProperty.adFee == null
                  ? 0
                  : form.clause.clauseProperty.adFee
              }}
            </a-form-model-item>
            <a-form-model-item style="width: 33%" label="公共能耗及物业代办费">
              {{
                form.clause.clauseProperty.publicFee == null
                  ? 0
                  : form.clause.clauseProperty.publicFee
              }}
            </a-form-model-item>
            <a-form-model-item style="width: 33%" label="建筑垃圾清运费">
              {{
                form.clause.clauseProperty.rubbishFee == null
                  ? 0
                  : form.clause.clauseProperty.rubbishFee
              }}
            </a-form-model-item>
          </div>
        </a-descriptions-item>
        <!-- 保证金 -->
        <a-descriptions-item
          label="保证金条款"
          :span="2"
          v-if="form.clause.clauseSureties.length > 0"
        >
          <div
            style="flex-wrap: wrap; justify-content: space-between"
            v-for="item in form.clause.clauseSureties"
            :key="item.id"
            class="flex-d-row"
          >
            <a-form-model-item label="保证金类型">
              {{
                item.type == 0
                  ? '履约保证金'
                  : item.type == 1
                  ? '质量保证金'
                  : item.type == 2
                  ? '装修保证金'
                  : item.type == 3
                  ? '水电保证金'
                  : item.type == 4
                  ? '物业管理服务费保证金'
                  : item.type == 5
                  ? '综合管理服务费保证金'
                  : item.type == 6
                  ? '装修及改造复原保证金'
                  : item.name
              }}
            </a-form-model-item>
            <a-form-model-item label="保证金金额">
              {{ item.fee }}
            </a-form-model-item>
            <a-form-model-item label="备注">
              {{ item.payDate == null ? '' : item.payDate }}
            </a-form-model-item>
          </div>
        </a-descriptions-item>
        <!-- 优惠 -->
        <a-descriptions-item
          :span="2"
          v-if="form.clause.clauseDiscounts.length > 0"
          label="优惠条款"
        >
          <div
            style="flex-wrap: wrap; justify-content: space-between"
            v-for="item in form.clause.clauseDiscounts"
            :key="item.id"
            class="flex-d-row"
          >
            <a-form-model-item label="优惠类型">
              {{
                item.type == 0
                  ? '免租期'
                  : item.type == 1
                  ? '装修期'
                  : item.type == 2
                  ? '单价折扣'
                  : item.type == 3
                  ? '单价减免'
                  : '每期应收减免'
              }}
            </a-form-model-item>
            <a-form-model-item
              v-if="item.type == 0 || item.type == 1"
              label="优惠"
            >
              {{ item.discount }}{{ item.discountType == 0 ? '天' : '月' }}
            </a-form-model-item>
            <a-form-model-item v-else label="优惠">
              {{ item.discount
              }}{{
                item.discountType == 0
                  ? '元/m².天'
                  : item.discountType == 5
                  ? '元/m².月'
                  : item.discountType == 6
                  ? '元/日'
                  : item.discountType == 1
                  ? '元/月'
                  : item.discountType == 3
                  ? '元/年'
                  : item.discountType == 4
                  ? '元/m²'
                  : '元'
              }}
            </a-form-model-item>
            <a-form-model-item label="开始期数">
              {{ item.discountTerm }}
            </a-form-model-item>
            <a-form-model-item label="持续期数">
              {{ item.discountDuration }}
            </a-form-model-item>
            <a-form-model-item label="备注">
              {{ item.note }}
            </a-form-model-item>
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="铺位" :span="2">
          <div class="flex-d-row" v-for="item in form.stores" :key="item.id">
            <a-form-model-item class="ant--input" label="楼栋">
              {{ item.buildingNameSnapshot }}栋
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="楼层">
              {{ item.floorNameSnapshot }}F
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="铺位名称">
              {{ item.storeNameSnapshot }}
            </a-form-model-item>
            <a-form-model-item class="ant--input" label="计租面积">
              {{ item.rentAreaSnapshot }}m²
            </a-form-model-item>
          </div>
        </a-descriptions-item>
      </a-descriptions>
      <!-- <div >
        <a-form-item style="margin-bottom:0;" label="账单总金额">
          <a-input
            v-model="clearpreview.allfee"
            disabled="disabled"
            placeholder="请输入账单总金额"
          />
        </a-form-item>
        <a-form-item style="margin-bottom:0;" label="物业费">
          <a-input
            v-model="clearpreview.feeProperty"
            disabled="disabled"
            placeholder="请输入账单总金额"
          />
        </a-form-item>
        <a-form-item style="margin-bottom:0;" label="保证金">
          <a-input
            v-model="clearpreview.surety"
            disabled="disabled"
            placeholder="请输入账单总金额"
          />
        </a-form-item>
        <a-form-item style="margin-bottom:0;" label="优惠折扣">
          <a-input
            v-model="clearpreview.discount"
            disabled="disabled"
            placeholder="请输入账单总金额"
          />
        </a-form-item>
        <a-form-item style="margin-bottom:0;" label="已付金额">
          <a-input
            v-model="clearpreview.payedRent"
            disabled="disabled"
            placeholder="请输入账单总金额"
          />
        </a-form-item>
        <a-form-item style="margin-bottom:0;" label="待结算金额">
          <a-input
            v-model="clearpreview.clear"
            disabled="disabled"
            placeholder="请输入账单总金额"
          />
        </a-form-item>
      </div> -->
    </a-modal>

    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        margin: 0;
        width: 100%;
        height: 60px;
        text-align: center;
        position: fixed;
        bottom: 0;
      "
    >
      <div
        style="
          justify-content: center;
          align-items: center;
          height: 60px;
          width: calc(100% - 250px);
        "
        class="flex-d-row"
        save-draw
      >
        <a-dropdown style="margin-right: 40px" :trigger="['click']">
          <a-button v-if="termdis != 0" type="primary" style="width: 105px"
            >添加条款</a-button
          >
          <a-menu slot="overlay">
            <a-menu-item
              v-if="
                form.clause.clauseLate.lateType == 0 ||
                form.clause.clauseLate.lateType == 1
              "
              disabled="disabled"
              key="1"
            >
              <a-icon type="user" />添加滞纳金条款
            </a-menu-item>
            <a-menu-item v-else @click="addLate" key="11">
              <a-icon type="user" />添加滞纳金条款
            </a-menu-item>
            <a-menu-item @click="addSureties" key="2">
              <a-icon type="user" />添加保证金条款
            </a-menu-item>
            <a-menu-item @click="addDiscounts" key="3">
              <a-icon type="user" />添加优惠条款</a-menu-item
            >
          </a-menu>
        </a-dropdown>
        <a-button
          v-if="bodytopdis != 0"
          html-type="submit"
          type="primary"
          @click="
            () => {
              this.termvisible = true
            }
          "
        >
          保存草稿
        </a-button>
        <div
          class="flex-d-row"
          v-show="permissionlist.indexOf('/business/sign:修改') > -1"
        >
          <a-button
            v-if="termdis != 0 && bodydis == 0 && bodytopdis == 0"
            html-type="submit"
            style="margin-left: 40px"
            type="primary"
            @click="onSubmit"
          >
            提交
          </a-button>
          <a-button
            v-if="termdis == 0 && bodydis == 1"
            type="primary"
            @click="onSubdis"
          >
            提交
          </a-button>
        </div>

        <a-button
          v-show="termdis == 1 && bodydis == 1 && bodytopdis == 1"
          html-type="submit"
          style="margin-left: 40px"
          type="primary"
          @click="onSubmit"
        >
          提交
        </a-button>
        <a-button
          v-show="termdis == 0 && bodydis == 0 && bodytopdis == 0"
          html-type="submit"
          style="margin-left: 40px"
          type="primary"
          @click="onbill"
        >
          查看账单
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  //   {
  //     title: "出租方",
  //     dataIndex: "partyAName",
  //     scopedSlots: { customRender: "partyAName" },
  //   },
  {
    title: '承租方',
    dataIndex: 'partyBName',
    scopedSlots: { customRender: 'partyBName' },
  },
  {
    title: '经营品牌',
    dataIndex: 'brandNames',
    scopedSlots: { customRender: 'brandNames' },
  },
  {
    title: '承租类型',
    dataIndex: 'partyBType',
    scopedSlots: { customRender: 'partyBType' },
  },
  {
    title: '合同编号',
    dataIndex: 'code',
  },
  {
    title: '铺位',
    dataIndex: 'stores',
    scopedSlots: { customRender: 'stores' },
  },
  {
    title: '负责人',
    dataIndex: 'belongToName',
  },
  {
    title: '签约人',
    dataIndex: 'signByName',
  },
  {
    title: '起止时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '合同状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const column = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '未提交',
  },
  1: {
    status: 'processing',
    text: '已提交',
  },
  2: {
    status: 'success',
    text: '生效中',
  },
  3: {
    status: 'error',
    text: '审核拒绝',
  },
  4: {
    status: 'processing',
    text: '撤回中',
  },
  5: {
    status: 'processing',
    text: '作废中',
  },
  6: {
    status: 'error',
    text: '已作废',
  },
  7: {
    status: 'processing',
    text: '变更主体',
  },
  8: {
    status: 'processing',
    text: '变更条款',
  },
  9: {
    status: 'processing',
    text: '终止中',
  },
  10: {
    status: 'processing',
    text: '已终止',
  },
}
const historycolumns = [
  {
    title: '审核人',
    dataIndex: 'auditorName',
  },
  {
    title: '审核备注',
    dataIndex: 'note',
  },
  {
    title: '审核结果',
    dataIndex: 'passed',
    scopedSlots: { customRender: 'passed' },
  },
  {
    title: '审核时间',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
  },
]
const modelcolumns = [
  {
    title: '账单日期',
    dataIndex: 'billDate',
  },
  {
    title: '付款日期',
    dataIndex: 'payDate',
  },
  {
    title: '总价',
    dataIndex: 'totalFee',
  },
]
const innerColumns = [
  { title: '费用名称', dataIndex: 'name', key: 'name' },
  {
    title: '起止时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' },
  },
  { title: '计租面积', dataIndex: 'area' },
  {
    title: '单价',
    dataIndex: 'fee',
  },
  {
    title: '单价类型',
    dataIndex: 'feeType ',
    scopedSlots: { customRender: 'feeType' },
  },
  {
    title: '金额',
    dataIndex: 'totalFee',
  },
]
let timeout

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  async function fake() {
    try {
      const res = await http.getNativelist(value)
      // console.log(res.data.data);
      const sult = res.data.data.brand
      const brand = []
      sult.forEach(item => {
        brand.push({
          value: item.id,
          text: item.brandName,
        })
      })
      callback(brand)
    } catch (ex) {
      console.log('error:' + ex)
    }
  }
  timeout = setTimeout(fake, 300)
}
const base64 = require('js-base64').Base64
const OSS = require('ali-oss')
let client
const api = process.env.VUE_APP_API_BASE_URL
import { nanoid } from 'nanoid'
import http from '../../../http'
import moment from 'moment'
import '../../../store'
// import { api } from "./../../https";
export default {
  data() {
    return {
      lookdisabled: false,
      visibless: false,
      showsub: 0,
      timesadd: '',
      permissionlist: [],
      moreact: '',
      termdis: 1,
      bodydis: 1,
      bodytopdis: 1,
      datalist: [],
      column,
      historycolumns,
      allindex: 0,
      error: 'none',
      errortext: '',
      error1: 'none',
      errortext1: '',
      expandedRowKeys: [],
      data: [],
      innerData: [],
      projectlist: [],
      buildlist: [],
      floorlist: [],
      storelist: [],
      modelcolumns,
      innerColumns,
      BusinessTypes: [],
      visrecord: false,
      logvisible: false,
      morevisible: false,
      termvisible: false,
      auditvisible: false,
      tempvisible: false,
      agreement: [],
      files: [],
      title: '',
      columns,
      projectName: '',
      contactindex: '',
      visible: false,
      modelvisible: false,
      visibles: false,
      placement: 'right',
      moment,
      disuseNote: '',
      labelCol: { span: 15 },
      wrapperCol: { span: 24 },
      linkId: '',
      submitNote: '',
      submitAttach: [],
      params: {
        current: 1,
        pageSize: 10,
        'cs.party_a_name': {
          value: '',
          op: '%like%',
        },
        'cs.party_b_name': {
          value: '',
          op: '%like%',
        },
        'cs.code': {
          value: undefined,
          op: '=',
        },
        'cub.id': {
          value: undefined,
          op: '=',
        },
        'cus.id': {
          value: undefined,
          op: '=',
        },
        'cs.state': {
          value: undefined,
          op: '=',
        },
        'cs.start_date': {
          value: '',
          op: '<',
        },
        'cs.end_date': {
          value: '',
          op: '>',
        },
      },
      total: 0,
      totalsign: 0,
      key: '',
      userlist: [],
      Enterpriselist: [],
      Clientlist: [],
      letetype: '0',
      letenumb: '',
      signslist: [],
      selectedRowKey: '',
      Id: '',
      range(start, end) {
        const result = []
        for (let i = start; i < end; i++) {
          result.push(i)
        }
        return result
      },
      isproject: 0,
      isbuild: 0,
      isfloor: 0,
      isstore: 0,
      termDurations: {
        type: '0',
        startDate: '',
        endDate: '',
        fee: 0,
        feeType: '0',
        rate: 0,
        term: '',
        stores: [],
      },
      startdata: [],
      intentionlist: [],
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      mome: '',
      textlist: [
        '出租方',
        '承租方',
        '合同编号',
        '负责人',
        '签约人',
        '起止时间',
        '状态',
      ],
      expand: false,
      foms: this.$form.createForm(this, { name: 'advanced_search' }),
      fom: this.$form.createForm(this, { name: 'coordinated' }),
      store: {
        storeId: '0',
        storeNameSnapshot: '',
        floorNameSnapshot: '',
        buildingNameSnapshot: '',
        totalAreaSnapshot: '',
        useAreaSnapshot: '',
        rentAreaSnapshot: '',
      },
      progresslist: [],
      checked: false,
      checkStore: [],
      checkStores: [],
      stores: [],
      floors: '',
      note: '',
      state: '',
      templateId: '',
      templist: [],
      clinttype: 0,
      clearpreview: {
        allfee: '',
        feeProperty: '',
        surety: '',
        discount: '',
        payedRent: '',
        clear: '',
      },
      form: {
        name: '',
        id: '',
        tempId: '',
        state: '0',
        code: '',
        intentionId: '',
        signBy: '',
        Date: [],
        signDate: '',
        belongTo: '',
        startDate: '',
        endDate: '',
        giveDate: '',
        openDate: '',
        partyAId: '',
        partyAName: '',
        partyAContact: '',
        partyAAddress: '',
        partyBId: '',
        partyBContact: '',
        partyBAddress: '',
        partyBNameP: '',
        partyBType: '',
        partyBGenderP: '',
        partyBIdP: '',
        partyBNameE: '',
        partyBPeopleE: '',
        partyBCerditE: '',
        projectId: '',
        agreement: '[]',
        signstermlist: [],
        files: '[]',
        stores: [],
        clause: {
          businessTypeIds: [],
          businessTypeId: '',
          customerBrandId: undefined,
          clauseTerm: {
            termType: '0',
            billType: '0',
            billType2: '0',
            billDateNum: '15',
            billDateType: '0',
            zeroType: '2',
            payDateNum: '10',
            payDateType: '0',
            firstPayType: '0',
            firstPayDate: '',
            term: '12',
            termDurations: [
              {
                type: '0',
                Date: '',
                startDate: '',
                endDate: '',
                fee: 0,
                feeType: '0',
                rate: '',
                store: [],
                stores: [],
              },
            ],
          },
          clauseProperty: {
            propertyFee: '0',
            adFee: '0',
            publicFee: '0',
            rubbishFee: '0',
            commonFee: '0',
            decorateFee: '0',
            propertyFeeType: '5',
            commonFeeType: '5',
            decorateFeeType: '5',
            adFeeType: '3',
            publicFeeType: '3',
            rubbishFeeType: '4',
            termType: '0',
            term: '0',
          },
          clauseSureties: [],
          clauseDiscounts: [],
          clauseLate: {},
        },
      },
      rules: {
        code: [
          {
            required: true,
            message: '合同编号不能为空',
            trigger: 'blur',
          },
        ],
        signBy: [
          {
            required: true,
            message: '签约人不能为空',
            trigger: 'change',
          },
        ],
        belongTo: [
          {
            required: true,
            message: '负责人不能为空',
            trigger: 'change',
          },
        ],
        signDate: [
          {
            required: true,
            message: '签约时间不能为空',
            trigger: 'change',
          },
        ],
        giveDate: [
          {
            required: true,
            message: '交房日期不能为空',
            trigger: 'change',
          },
        ],
        openDate: [
          {
            required: true,
            message: '开业日期不能为空',
            trigger: 'change',
          },
        ],
        partyAId: [
          {
            required: true,
            message: '出租方不能为空',
            trigger: 'change',
          },
        ],
        partyAName: [
          {
            required: true,
            message: '出租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyAContact: [
          {
            required: true,
            message: '出租方联系电话格式错误或为空',
            trigger: 'blur',
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          },
        ],
        partyAAddress: [
          {
            required: true,
            message: '出租方联系地址不能为空',
            trigger: 'blur',
          },
        ],
        partyBId: [
          {
            required: true,
            message: '承租方不能为空',
            trigger: 'change',
          },
        ],
        partyBNameP: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyBGenderP: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyBIdP: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyBNameE: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyBPeopleE: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyBCerditE: [
          {
            required: true,
            message: '承租方名称不能为空',
            trigger: 'blur',
          },
        ],
        partyBContact: [
          {
            required: true,
            message: '承租方联系电话格式错误或为空',
            trigger: 'blur',
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          },
        ],
        partyBAddress: [
          {
            required: true,
            message: '承租方联系地址不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    statusFilter(type) {
      if (type == null) {
        return ' '
      }
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      if (type == null) {
        return ' '
      }
      return statusMap[type].status
    },
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0] + ' ' + times[1]
      }
    },
    text(text) {
      if (!text) {
        return
      }
      if (text.length <= 8 || text == null) {
        return text
      } else {
        return text.substr(0, 8) + '..'
      }
    },
  },
  methods: {
    onstartend(e) {
      this.params['cs.start_date'].value = moment(e).format('YYYY-MM-DD')
      this.params['cs.end_date'].value = moment(e).format('YYYY-MM-DD')
    },
    handleSearchs(e) {
      e.preventDefault()
      this.params.current = 1
      this.foms.validateFields((error, values) => {
        this.params['cs.party_a_name'].value = values.出租方
        this.params['cs.party_b_name'].value = values.承租方
        this.params['cs.code'].value = values.合同编号
      })
      this.getSign()
    },
    handleReset() {
      this.params['cub.id'].value = undefined
      this.params['cus.id'].value = undefined
      this.params['cs.state'].value = undefined
      this.params['cs.start_date'].value = undefined
      this.params['cs.end_date'].value = undefined
      this.foms.resetFields()
    },
    toggle() {
      this.expand = !this.expand
    },
    handleSearch(value) {
      fetch(value, brand => (this.datalist = brand))
    },
    handleChange(value) {
      //   console.log(value);
      this.form.clause.customerBrandId = value
      fetch(value, brand => (this.datalist = brand))
    },
    onAddyears(ind) {
      if (this.form.Date != []) {
        this.form.Date = [
          moment(this.form.Date[0]),
          moment(this.form.Date[0]).add(ind, 'years'),
        ]
      } else {
        this.form.Date = [
          moment(),
          moment().subtract('1', 'days').add(ind, 'years'),
        ]
      }
    },
    oncheckAll(e) {
      console.log(e)
    },
    Close() {
      this.visibless = false
    },
    handOk() {
      if (this.contactindex == undefined) {
        this.checkStores.forEach(it => {
          this.form.stores.push(it)
        })
        // console.log(this.checkStores);
      } else {
        // console.log(this.contactindex);
        this.form.stores.splice(this.contactindex, 1, this.checkStores[0])
      }
      this.onclose()
    },
    onclientA() {
      //   console.log(this.form.partyAId);
      this.getenterpriseid(this.form.partyAId)
    },
    async getenterpriseid(id) {
      try {
        const res = await http.getenterpriseid(id)
        const { success, data } = res.data
        if (success) {
          this.form.partyAName = data.enterprise.name
          this.form.partyAContact = data.enterprise.contactMobile
          this.form.partyAAddress = data.enterprise.address
        }
      } catch (e) {
        console.log(e)
      }
    },
    onclientB() {
      this.getClientId(this.form.partyBId)
    },
    async getClientId(id) {
      try {
        const res = await http.getClientId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.clinttype = data.client.type
          var mobil = ''
          data.client.contacts.forEach((item, index) => {
            if (index == 0) {
              mobil = item.mobile
            } else {
              mobil = mobil + ',' + item.mobile
            }
          })
          this.form.partyBType = data.client.type

          if (data.client.type == 0) {
            this.form.partyBNameE = data.client.name
            this.form.partyBPeopleE = data.client.infoLegalPersonName
            this.form.partyBCerditE = data.client.infoCreditCode
          } else if (data.client.type == 1) {
            this.form.partyBNameP = data.client.name
            this.form.partyBIdP = data.client.idNo
            if (data.client.gender == null) {
              this.form.partyBGenderP = undefined
            } else {
              this.form.partyBGenderP = data.client.gender + ''
            }
          }
          this.form.partyBContact = mobil
          this.form.partyBAddress = data.client.infoRegLocation
        }
      } catch (e) {
        console.log(e)
      }
    },
    onclose() {
      this.visibles = false
      this.stores = []
      this.checkStore = []
      this.checkStores = []
      this.store = {
        storeId: '0',
        storeNameSnapshot: '',
        floorNameSnapshot: '',
        buildingNameSnapshot: '',
        totalAreaSnapshot: '',
        useAreaSnapshot: '',
        rentAreaSnapshot: '',
      }
    },
    deletestore(e) {
      this.form.stores.splice(e, 1)
      this.onclose()
    },
    Addmobile(e, index) {
      //   console.log(e);
      if (e == undefined) {
        this.contactindex = undefined
      } else {
        this.contactindex = index
        // console.log(e.id);
        this.getStoreinfo(e.storeId)
      }
      this.storetype(this.floors || this.floorlist[0].id, 0)
      this.visibles = true
    },
    async getStoreinfo(id) {
      try {
        const res = await http.getStoreinfo(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.store.storeId = data.store.id
          this.store.storeNameSnapshot = data.store.name
          this.store.floorNameSnapshot = data.store.floorName
          this.store.buildingNameSnapshot = data.store.buildingName
          this.store.totalAreaSnapshot = data.store.totalArea
          this.store.useAreaSnapshot = data.store.useArea
          this.store.rentAreaSnapshot = data.store.rentArea
          this.stores = data.store
          this.stores.state = data.store.state + ''
          this.stores.locked = data.store.locked + ''
          this.stores.rentState = data.store.rentState + ''
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    test(index) {
      this.fom.validateFields((err, values) => {
        // console.log(values);
        if (this.form.startDate == '') {
          this.error1 = 'error'
          this.errortext1 = '起止时间不能为空'
          this.$message.error('请先输入租期起止', 10)
        } else {
          this.error1 = 'none'
          this.errortext1 = ''
        }
        if (this.form.clause.clauseTerm.termDurations[index].startDate == '') {
          this.error = 'error'
          this.errortext = '起止时间不能为空'
        } else {
          this.error = 'none'
          this.errortext = ''
        }
        // console.log(this.form.clause.clauseDiscounts[index].note);
        this.$nextTick(() => {
          this.fom.setFieldsValue({
            Ddiscount: values.Ddiscount,
            DdiscountTerm: values.DdiscountTerm,
            DdiscountDuration: values.DdiscountDuration,
            Dnote: values.Dnote,
          })
        })
        this.form.clause.clauseDiscounts[index].discount = values.Ddiscount
        this.form.clause.clauseDiscounts[index].discountTerm =
          values.DdiscountTerm
        this.form.clause.clauseDiscounts[index].discountDuration =
          values.DdiscountDuration
        this.form.clause.clauseDiscounts[index].note = values.Dnote
      })
    },
    ondata(e) {
      //合同 0
      //   console.log(e);
      this.visibless = true
      this.showsub = 0
      this.form.id = e.id
      if (e.agreement.length > 0) {
        this.agreement = JSON.parse(e.agreement)
        var a = []
        this.agreement.forEach(item => {
          const url = client.signatureUrl(item.name)
          a.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: item.realName,
            uid: nanoid(),
            status: 'done',
            url: url,
          })
        })
        this.agreement = a
      }
      if (e.state != 0) {
        this.lookdisabled = true
        this.title = '查看合同'
      } else if (e.state == 0) {
        this.lookdisabled = false
        this.title = '上传合同'
      }
    },
    oncontract(e) {
      //补充资料 1
      this.visibless = true
      this.showsub = 1
      this.form.id = e.id
      var a = []
      if (e.agreement.length > 0) {
        this.files = JSON.parse(e.files)
        this.files.forEach(item => {
          const url = client.signatureUrl(item.name)
          a.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: item.realName,
            uid: nanoid(),
            status: 'done',
            url: url,
          })
        })
        this.files = a
      }
      if (e.state != 0) {
        this.lookdisabled = true
        this.title = '查看资料'
      } else {
        this.lookdisabled = false
        this.title = '补充资料'
      }
    },
    handleChangeinfo(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      //   console.log(info);
      this.putObject(
        1,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    handleChangeinfos({ fileList }) {
      if (this.files.length > fileList.length) {
        this.files = fileList
      }
      this.form.files = JSON.stringify(fileList)
    },
    handlefile(info) {
      //   console.log(info);
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      //   console.log(info);
      this.putObject(
        2,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    async handlePreview(file) {
      //   console.log(file);
      const urls = file.url.replaceAll(
        'sccncloud.oss-cn-shanghai.aliyuncs.com',
        'yunfiles.shangchan.cn'
      )
      if (file.url) {
        var url = urls //要预览文件的访问地址
        const u = 'https://preview.shangchan.cn/preview'
        window.open(
          u + '/onlinePreview?url=' + encodeURIComponent(base64.encode(url))
        )
      }
    },
    handlefiles({ fileList }) {
      if (this.agreement.length > fileList.length) {
        this.agreement = fileList
      }
      this.form.agreement = JSON.stringify(fileList)
    },
    async putObject(i, client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        const url = client.signatureUrl(result.name)
        var a = [result]
        if (i == 1) {
          a.forEach(item => {
            this.files.push({
              bucket: 'sccncloud2',
              name: item.name,
              realName: realName,
              uid: id,
              status: 'done',
              url: url,
            })
          })
        } else if (i == 2) {
          a.forEach(item => {
            this.agreement.push({
              bucket: 'sccncloud2',
              name: item.name,
              uid: id,
              realName: realName,
              status: 'done',
              url: url,
            })
          })
        } else if (i == 3) {
          //审核
          a.forEach(item => {
            this.submitAttach.push({
              bucket: 'sccncloud2',
              name: item.name,
              realName: realName,
              type: name,
              uid: id,
              status: 'done',
              url: url,
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    subdata() {
      if (this.showsub == 0) {
        //合同
        this.getsigngreement(this.form.id, JSON.stringify(this.agreement))
      } else {
        //补充资料
        this.getsignfile(this.form.id, JSON.stringify(this.files))
      }
      this.visibless = false
    },
    disabledDate(current) {
      //   console.log(current);
      // Can not select days before today and today
      return current && current < moment(this.form.startDate).startOf('days')
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        }
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      }
    },
    onChangestore(index) {
      var list = []
      this.form.clause.clauseTerm.termDurations[index].store.forEach(item => {
        list.push({
          storeId: item,
        })
      })
      //   console.log(this.form.clause.clauseTerm.termDurations[index].store);
      this.form.clause.clauseTerm.termDurations[index].stores = list
      //   console.log(this.form.clause.clauseTerm.termDurations[index].store);
    },
    onAllclick(index) {
      var list = []
      var lists = []
      if (
        this.form.clause.clauseTerm.termDurations[index].stores.length !=
        this.form.stores.length
      ) {
        this.form.stores.forEach(item => {
          lists.push({
            storeId: item.storeId,
          })
          list.push(item.storeId)
        })
        this.form.clause.clauseTerm.termDurations[index].store = list
        this.form.clause.clauseTerm.termDurations[index].stores = lists
      } else if (
        this.form.clause.clauseTerm.termDurations[index].stores.length ==
        this.form.stores.length
      ) {
        this.form.clause.clauseTerm.termDurations[index].store = []
        this.form.clause.clauseTerm.termDurations[index].stores = []
      }
    },
    onexpand(expanded, record) {
      //   console.log(expanded, record);
      this.innerData = record.children
      record.children.forEach(item => {
        if (item.name == '免租期租金减免') {
          if (typeof item.totalFee == 'number') {
            item.totalFee = `${item.totalFee}(${
              record.children[0].totalFee + item.totalFee
            })`
          }
        }
      })
      if (expanded) {
        this.expandedRowKeys = [record.id]
      } else {
        this.expandedRowKeys = []
      }
    },
    onrecords(e) {
      this.visrecord = true
      let linktype = 21
      if (e.state == 1) {
        linktype = 21
      } else if (e.state == 4) {
        linktype = 22
      } else if (e.state == 5) {
        linktype = 23
      } else if (e.state == 7) {
        linktype = 24
      } else if (e.state == 8) {
        linktype = 25
      }
      this.getauditrecord(linktype, e.id, e.processId, e.version)
    },
    oncanrecord() {
      this.visrecord = false
      setTimeout(() => {
        this.progresslist = []
      }, 100)
    },
    //提交文件
    async getsignfile(id, file) {
      try {
        const res = await http.getsignfile(id, file)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    }, //合同
    async getsigngreement(id, file) {
      try {
        const res = await http.getsigngreement(id, file)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getauditrecord(linkType, linkId, processId, version) {
      try {
        const res = await http.getauditrecord(
          linkType,
          linkId,
          processId,
          version
        )
        const { success, data } = res.data
        if (success) {
          this.progresslist = data.record
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    // 删除
    onDetele(e) {
      //   console.log(e.id);
      this.deletesignterm(e.id)
    },
    onClose() {
      this.bodydis = 1
      this.termdis = 1
      this.bodytopdis = 1
      this.visible = false
      this.Enterpriselist = []
      this.Clientlist = []
      this.total = this.totalsign
      this.Id = ''
      this.form = {
        name: '',
        id: '',
        tempId: '',
        state: '0',
        code: '',
        intentionId: '',
        Date: [],
        signDate: '',
        projectId: '',
        belongTo: '',
        signBy: '',
        startDate: '',
        endDate: '',
        giveDate: '',
        openDate: '',
        partyAId: '',
        partyAName: '',
        partyAContact: '',
        partyAAddress: '',
        partyBId: '',
        partyBName: '',
        partyBContact: '',
        partyBAddress: '',
        agreement: '[]',
        signstermlist: [],
        files: '[]',
        stores: [],
        clause: {
          businessTypeIds: [],
          businessTypeId: '',
          customerBrandId: '',
          clauseLate: {},
          clauseTerm: {
            termType: '0',
            billType: '0',
            billType2: '0',
            billDateNum: '15',
            billDateType: '0',
            payDateNum: '10',
            payDateType: '0',
            zeroType: '2',
            firstPayType: '0',
            firstPayDate: '',
            term: '12',
            termDurations: [
              {
                type: '0',
                Date: '',
                startDate: '',
                endDate: '',
                fee: 0,
                feeType: '0',
                rate: '',
                store: [],
                stores: [],
              },
            ],
          },
          clauseProperty: {
            propertyFee: '0',
            adFee: '0',
            publicFee: '0',
            rubbishFee: '0',
            commonFee: '0',
            decorateFee: '0',
            propertyFeeType: '5',
            commonFeeType: '5',
            decorateFeeType: '5',
            adFeeType: '3',
            publicFeeType: '3',
            rubbishFeeType: '4',
            termType: '0',
            term: '0',
          },
          clauseSureties: [],
          clauseDiscounts: [],
        },
      }
      this.store = {
        storeId: '0',
        storeNameSnapshot: '',
        floorNameSnapshot: '',
        buildingNameSnapshot: '',
        totalAreaSnapshot: '',
        useAreaSnapshot: '',
        rentAreaSnapshot: '',
      }
      ;(this.stores = []), (this.files = [])
      this.agreement = []
    },
    onChangetype(e) {
      this.form.clause.businessTypeId = e[e.length - 1]
      //   console.log(e);
    },
    onSubmit() {
      if (this.form.clause.clauseTerm.termDurations[0].startDate == '') {
        this.error = 'error'
        this.errortext = '起止时间不能为空'
        this.$message.warning('起止时间不能为空')
        return
      } else {
        this.error = 'none'
        this.errortext = ''
      }
      if (this.form.startDate == '') {
        this.error1 = 'error'
        this.errortext1 = '租期起止不能为空'
        this.$message.warning('租期起止不能为空')
        return
      } else {
        this.error1 = 'none'
        this.errortext1 = ''
      }
      if (typeof this.form.agreement == 'object') {
        this.form.agreement = JSON.stringify(this.form.agreement)
      }
      if (typeof this.form.files == 'object') {
        this.form.files = JSON.stringify(this.form.files)
      }
      //   console.log("submit!", this.form);
      if (this.form.clause.clauseTerm.term == null) {
        this.$message.error('租赁条款付款周期不能为空')
        return
      }
      if (this.form.clause.clauseTerm.billDateNum == null) {
        this.$message.error('租赁条款账单日期不能为空')
        return
      }
      if (this.form.clause.clauseTerm.payDateNum == null) {
        this.$message.error('租赁条款缴费日期不能为空')
        return
      }
      if (this.form.clause.clauseProperty.propertyFee == null) {
        this.$message.error('物业管理服务费不能为空')
        return
      }
      if (this.form.clause.clauseProperty.commonFee == null) {
        this.$message.error('综合管理服务费不能为空')
        return
      }
      if (this.form.clause.clauseProperty.decorateFee == null) {
        this.$message.error('装修管理服务费不能为空')
        return
      }
      if (this.form.clause.clauseProperty.adFee == null) {
        this.$message.error('运营推广费不能为空')
        return
      }
      if (this.form.clause.clauseProperty.publicFee == null) {
        this.$message.error('公共能耗及物业代办费 不能为空')
        return
      }
      if (this.form.clause.clauseProperty.rubbishFee == null) {
        this.$message.error('建筑垃圾清运费不能为空')
        return
      }
      this.billSign(this.form)
    },
    onbill() {
      this.billSign(this.form)
    },
    handleOk() {
      //   console.log("submit!", this.form);
      if (this.bodydis == 0) {
        //变更条款
        this.auditvisible = true
        return
      }
      if (this.Id == '') {
        this.AddSign(this.form)
      } else if (this.Id != '') {
        this.putSign(this.form)
      }
      setTimeout(() => {
        this.handleCancel()
      }, 200)
    },
    handleCancel() {
      this.modelvisible = false
      this.data = []
      this.innerData = []
    },
    onyouhui(e, index) {
      this.form.clause.clauseDiscounts[index].youhui = e
    },
    addDurations(index) {
      //   var moment = require("moment");
      this.$nextTick(function () {
        //不使用this.$nextTick()方法会报错
        const numb = index + 1
        this.form.clause.clauseTerm.termDurations[numb].Date = [
          this.timesadd,
          this.form.endDate,
        ]
      })
      if (
        this.form.startDate !=
        this.form.clause.clauseTerm.termDurations[0].startDate
      ) {
        this.$message.error('铺位租期开始时间必须于总租期开始时间一致!')
        this.form.clause.clauseTerm.termDurations[index].Date = []
        this.form.clause.clauseTerm.termDurations[index].startDate = ''
        this.form.clause.clauseTerm.termDurations[index].endDate = ''
      }
      if (
        moment(this.form.endDate).isBefore(
          this.form.clause.clauseTerm.termDurations[index].endDate
        ) ||
        this.form.clause.clauseTerm.termDurations[index].endDate ==
          this.form.endDate
      ) {
        this.$message.error('超过租期结束时间!')
      } else {
        const termDurations = {
          type: '0',
          startDate: this.timesadd,
          endDate: moment(this.form.endDate).format('YYYY-MM-DD'),
          fee: 0,
          feeType: '0',
          rate: '',
          term: '',
          stores: [],
        }
        this.form.clause.clauseTerm.termDurations.push(termDurations)
      }
    },
    // addProperty() {
    //   this.form.clause.clauseProperty = this.clauseProperty;
    // },
    addLate() {
      this.form.clause.clauseLate = {
        days: '0',
        lateType: '0',
        fee: '0',
        limit: '0',
      }
    },
    addSureties() {
      const clauseSureties = {
        type: '0',
        name: '',
        fee: '0',
        payDateType: '0',
        payDate: '',
      }
      this.form.clause.clauseSureties.push(clauseSureties)
    },
    addDiscounts() {
      const clauseDiscounts = {
        type: '0',
        discount: 0,
        discountTerm: 0,
        discountDuration: 0,
        note: '',
        discountType: '0',
      }
      this.form.clause.clauseDiscounts.push(clauseDiscounts)
    },
    deleteLate() {
      this.form.clause.clauseLate = {}
    },
    deleteSureties(index) {
      this.form.clause.clauseSureties.splice(index, 1)
    },
    deleteDiscounts(index) {
      this.form.clause.clauseDiscounts.splice(index, 1)
    },
    deleteDurations(index) {
      if (index == 0) {
        this.$message.error('最后一层不可删除')
      } else {
        this.form.clause.clauseTerm.termDurations.splice(index, 1)
      }
    },
    onChangetime2(e) {
      //   console.log(this.form.Date);
      var moment = require('moment')
      var a = moment(e[0]).format('YYYY-MM-DD')
      var b = moment(e[1]).format('YYYY-MM-DD')
      this.form.startDate = a
      this.form.endDate = b
      this.error1 = 'none'
      this.errortext1 = ''
      this.form.clause.clauseTerm.termDurations[0].Date = e
      this.form.clause.clauseTerm.termDurations[0].startDate = a
      this.form.clause.clauseTerm.termDurations[0].endDate = b
    },
    onChangetime3() {
      this.form.clause.clauseTerm.firstPayDate = moment(
        this.form.clause.clauseTerm.firstPayDate
      ).format('YYYY-MM-DD')
    },
    onChangetime4(index) {
      this.form.clause.clauseSureties[index].payDate = moment(
        this.form.clause.clauseSureties[index].payDate
      ).format('YYYY-MM-DD')
    },
    // onChangetime7() {
    //   if (this.form.signDate == "") {
    //     return;
    //   }
    //   this.form.signDate = moment(this.form.signDate).format("YYYY-MM-DD");
    // },
    // onChangetime6() {
    //   if (this.form.giveDate == "") {
    //     return;
    //   }
    //   this.form.giveDate = moment(this.form.giveDate);
    // },
    // onChangetime5() {
    //   if (this.form.openDate == "") {
    //     return;
    //   }
    //   this.form.openDate = moment(this.form.openDate).format("YYYY-MM-DD");
    // },
    onChangetime1(index) {
      var moment = require('moment')
      this.timesadd = moment(
        this.form.clause.clauseTerm.termDurations[index].Date[1]
      )
        .add(1, 'days')
        .format('YYYY-MM-DD')

      if (index != 0) {
        this.mome = moment(
          this.form.clause.clauseTerm.termDurations[index - 1].Date[1]
        )
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      if (
        this.form.startDate !=
        this.form.clause.clauseTerm.termDurations[0].startDate
      ) {
        this.$message.error('铺位租期开始时间必须于总租期开始时间一致!')
        this.form.clause.clauseTerm.termDurations[index].Date = []
        this.form.clause.clauseTerm.termDurations[index].startDate = ''
        this.form.clause.clauseTerm.termDurations[index].endDate = ''
      }
      var date = this.form.clause.clauseTerm.termDurations[index].Date
      var c = moment(date[0]).format('YYYY-MM-DD')
      var d = moment(date[1]).format('YYYY-MM-DD')
      if (moment(this.form.endDate).isAfter(d) || this.form.endDate == d) {
        this.form.clause.clauseTerm.termDurations[index].startDate = c
        this.form.clause.clauseTerm.termDurations[index].endDate = d
      } else {
        this.$message.error('不能超过租期时间！')
        this.form.clause.clauseTerm.termDurations[index].Date = null
        return
      }
      if (index == 0) {
        return
      } else {
        if (c == this.mome) {
          //   console.log(1);
        } else {
          this.$message.error('条款起止时间必须衔接和不能重复！')
          this.form.clause.clauseTerm.termDurations[index].Date = null
          return
        }
      }
    },
    onintent(e) {
      this.getIntentionId(e)
    },
    callbacks(e) {
      this.params.current = 1
      if (e == 1) {
        this.getSign()
      } else if (e == 2) {
        this.postsignterm()
      }
    },
    // 编辑
    onEdit(item, d) {
      this.visible = true
      this.title = '编辑'
      this.getClientlist()
      this.getenterpriselist()
      if (d == 11) {
        this.getsignterm(item.id)
      } else {
        this.getSignchangeterms(item.id)
        this.form.id = item.id
        this.Id = item.id
      }
      this.buildingid(
        JSON.parse(localStorage.getItem('store-userlist')).projectInfo.projectId
      )
    },
    onlook(item) {
      this.visible = true
      this.bodydis = 0
      this.bodytopdis = 0
      this.termdis = 0
      this.getClientlist()
      this.getenterpriselist()
      this.form.id = item.id
      this.Id = 'look'
      if (item.state == 0 || item.state == 1) {
        this.getSignchangeterms(item.id)
      } else {
        this.getSignId(item.id)
      }
    },
    onpagesize(e) {
      this.params.current = e
      if (this.modelvisible == true) {
        // console.log(e, 1);
      } else {
        this.getSign()
      }
    },
    showDrawer() {
      fetch('', brand => (this.datalist = brand))
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
      const users = JSON.parse(localStorage.getItem('store-userlist'))
      this.form.projectId = users.projectInfo.projectId
      this.projectName = users.projectInfo.projectName
      this.buildingid(users.projectInfo.projectId)
      this.getClientlist()
      this.getIntentionlist()
      this.getenterpriselist()
      this.form.belongTo = users.id + ''
      this.form.signBy = users.id + ''
    },

    //确定
    onmoreaction() {
      this.morevisible = false
      if (this.moreact == 1) {
        //变更
        console.log()
      } else if (this.moreact == 3) {
        //续租
        if (this.letetype == 0) {
          this.form.endDate = moment(this.form.endDate).add(this.letenumb, 'M')
        } else {
          this.form.endDate = moment(this.form.endDate).add(this.letenumb, 'y')
        }
        const date = []
        date.push(moment(this.form.startDate), moment(this.form.endDate))
        this.form.Date = date
        this.visible = true
      } else if (this.moreact == 9) {
        this.getSignsubmitId(this.form.id)
      }
      this.onmorecancel()
    },
    //取消
    onmorecancel() {
      this.letenumb = ''
      this.letetype = '0'
      this.morevisible = false
      this.selectedRowKey = ''
      this.disuseNote = ''
    },
    onSubdis() {
      this.auditvisible = true
    },
    //续租
    onrelet(e, index) {
      this.moreact = index
      this.form.id = e
      this.morevisible = true
      this.getSignId(e)
      //   setTimeout(() => {
      //     this.form.startDate = this.form.endDate;
      //   }, 100);
    },
    //终止
    onend(e, index) {
      this.moreact = index
      this.morevisible = true
      this.getSignendid(e.id)
      this.getSignpreviewId(e.id)
    },
    //变更
    onchanges(e, index) {
      this.moreact = index
      this.form.id = e.id
      this.linkId = e.id
      this.morevisible = true
      this.getSignId(this.form.id)
    },
    changemain(i) {
      this.getClientlist()
      this.getenterpriselist()
      this.termdis = 0
      this.bodytopdis = 0
      this.state = i
      this.visible = true
      this.morevisible = false
    },
    changekuan(i) {
      this.bodydis = 0
      this.bodytopdis = 0
      this.visible = true
      this.morevisible = false
      this.state = i
      this.getSignchangeInfo(this.form.id)
    },
    //删除
    showConfirm(id) {
      const that = this
      this.$confirm({
        title: '是否同时解除铺位锁定?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.getSigndelete(id, 1)
          }, 100)
        },
        onCancel() {
          setTimeout(() => {
            that.getSigndelete(id, 0)
          }, 100)
        },
      })
    },

    async getSignsubmitId(id) {
      try {
        const res = await http.getSignsubmitId(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //预览清单
    async getSignpreviewId(id) {
      try {
        const res = await http.getSignpreviewId(id)
        const { success, data } = res.data
        if (success) {
          this.clearpreview = data.clear
          this.clearpreview.allfee =
            Number(data.clear.feeProperty) +
            Number(data.clear.surety) +
            Number(data.clear.discount) +
            Number(data.clear.payedRent) +
            Number(data.clear.clear)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getSigndelete(id, unlock) {
      try {
        const res = await http.getSigndelete(id, unlock)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //退租

    async getsigndisuse(submitAttach) {
      try {
        const res = await http.getsigndisuse({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.$message.success('操作成功')
          this.auditvisible = false
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getSignchangeInfo(id) {
      try {
        const res = await http.getSignchangeInfo(id)
        const { success, msg, data } = res.data
        if (success) {
          this.form.clause.clauseTerm.termDurations[0].startDate =
            data.startDate
          const date = []
          date.push(
            moment(this.form.clause.clauseTerm.termDurations[0].startDate),
            moment(this.form.clause.clauseTerm.termDurations[0].endDate)
          )
          this.form.clause.clauseTerm.termDurations[0].Date = date
          if (this.form.clause.clauseTerm.firstPayType == 1) {
            const days = moment(moment(data.startDate)).diff(
              moment(this.form.startDate),
              'days'
            )
            // console.log(days);
            this.form.clause.clauseTerm.firstPayDate = moment(
              this.form.clause.clauseTerm.firstPayDate
            ).add(days, 'days')
          }

          this.$message.success('操作成功')
          this.timer()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getSignchange1(submitAttach) {
      try {
        const res = await http.getSignchange1({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
          linkObject: {
            partyAId: this.form.partyAId,
            partyAName: this.form.partyAName,
            partyAContact: this.form.partyAContact,
            partyAAddress: this.form.partyAAddress,
            partyBId: this.form.partyBId,
            partyBName: this.form.partyBName,
            partyBContact: this.form.partyBContact,
            partyBAddress: this.form.partyBAddress,
          },
        })
        const { success, msg } = res.data
        if (success) {
          this.onClose()
          this.onauditcancel()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getSignchange2(submitAttach) {
      try {
        const res = await http.getSignchange2({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
          linkObject: this.form,
        })
        const { success, msg } = res.data
        if (success) {
          this.onClose()
          this.handleCancel()
          this.onauditcancel()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getSign() {
      try {
        const res = await http.getSign(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          data.signs.records.forEach(item => {
            if (item.state == 0 || item.state == 1) {
              item['brandNames'] = item.brandNameChange
            } else {
              item['brandNames'] = item.brandName
            }
          })
          this.signslist = data.signs.records
          this.total = data.signs.total
          this.totalsign = data.signs.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getNativelistID(id) {
      try {
        const res = await http.getNativelistID(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          fetch(data.brand.brandName, brand => (this.datalist = brand))
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getIntentionId(id) {
      try {
        const res = await http.getIntentionId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          var stores = []
          data.intention.stores.forEach(item => {
            stores.push({
              storeId: item.storeId,
              storeNameSnapshot: item.storeName,
              floorNameSnapshot: item.floorName,
              buildingNameSnapshot: item.buildingName,
              totalAreaSnapshot: item.totalArea,
              useAreaSnapshot: item.useArea,
              rentAreaSnapshot: item.rentArea,
            })
          })
          this.form.stores = stores
          this.form.signBy = data.intention.signBy + ''
          this.form.partyAId = data.intention.partyAId
          this.form.partyAName = data.intention.partyAName
          this.form.partyAContact = data.intention.partyAContact
          this.form.partyAAddress = data.intention.partyAAddress
          this.form.partyBId = data.intention.partyBId
          this.getClientId(data.intention.partyBId)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getIntentionlist() {
      try {
        const res = await http.getIntentionlist(
          this.params.current,
          this.params.pageSize
        )
        const { success, data } = res.data
        if (success) {
          this.intentionlist = data.intentions
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async deletesignterm(id) {
      try {
        const res = await http.deletesignterm(id)
        const { success, msg } = res.data
        if (success) {
          this.postsignterm()
          this.$message.success(msg)
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getSignId(id) {
      try {
        const res = await http.getSignId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.sign.clause.customerBrandId == null) {
            fetch('', brand => (this.datalist = brand))
          } else {
            if (data.sign.clause.customerBrandId != '') {
              this.getNativelistID(data.sign.clause.customerBrandId)
            }
          }
          var aaa = data.sign.clause
          aaa.clauseProperty.termType = aaa.clauseProperty.termType + ''
          if (aaa.clauseProperty.propertyFeeType == null) {
            aaa.clauseProperty.propertyFeeType = '5'
            aaa.clauseProperty.commonFeeType = '5'
            aaa.clauseProperty.decorateFeeType = '5'
            aaa.clauseProperty.adFeeType = '3'
            aaa.clauseProperty.publicFeeType = '3'
            aaa.clauseProperty.rubbishFeeType = '4'
          } else {
            aaa.clauseProperty.propertyFeeType =
              aaa.clauseProperty.propertyFeeType + ''
            aaa.clauseProperty.commonFeeType =
              aaa.clauseProperty.commonFeeType + ''
            aaa.clauseProperty.decorateFeeType =
              aaa.clauseProperty.decorateFeeType + ''
            aaa.clauseProperty.adFeeType = aaa.clauseProperty.adFeeType + ''
            aaa.clauseProperty.publicFeeType =
              aaa.clauseProperty.publicFeeType + ''
            aaa.clauseProperty.rubbishFeeType =
              aaa.clauseProperty.rubbishFeeType + ''
          }
          aaa.clauseTerm.termType = aaa.clauseTerm.termType + ''
          aaa.clauseTerm.billType = aaa.clauseTerm.billType + ''
          aaa.clauseTerm.billType2 = aaa.clauseTerm.billType2 + ''
          aaa.clauseTerm.billDateType = aaa.clauseTerm.billDateType + ''
          aaa.clauseTerm.payDateType = aaa.clauseTerm.payDateType + ''
          aaa.clauseTerm.firstPayType = aaa.clauseTerm.firstPayType + ''
          aaa.clauseTerm.zeroType = aaa.clauseTerm.zeroType + ''
          if (data.sign.clause.clauseLate == null) {
            data.sign.clause.clauseLate = {
              days: '1',
              lateType: '0',
              fee: '1',
              limit: '5',
            }
          }
          data.sign.Date = []
          data.sign.clause.clauseTerm.termDurations.forEach(it => {
            it.Date = []
          })
          this.form = data.sign
          this.getClientId(data.sign.partyBId)
          const date = []
          date.push(moment(data.sign.startDate), moment(data.sign.endDate))
          this.form.Date = date
          //租赁条款
          for (
            var i = 0;
            i < data.sign.clause.clauseTerm.termDurations.length;
            i++
          ) {
            data.sign.clause.clauseTerm.termDurations[i].type =
              data.sign.clause.clauseTerm.termDurations[i].type + ''
            const stored = []
            aaa.clauseTerm.termDurations[i].stores.forEach(item => {
              stored.push(item.storeId)
            })
            data.sign.clause.clauseTerm.termDurations[i].store = stored
            // console.log(store);
            // console.log(aaa.clauseTerm.termDurations[i].store);
            data.sign.clause.clauseTerm.termDurations[i].feeType =
              data.sign.clause.clauseTerm.termDurations[i].feeType + ''
            const da = []
            da.push(
              moment(aaa.clauseTerm.termDurations[i].startDate),
              moment(aaa.clauseTerm.termDurations[i].endDate)
            )
            this.form.clause.clauseTerm.termDurations[i].Date = da
            // console.log(this.form.clause);
          }
          //   this.onChangetime1(i);
          for (var b = 0; b < this.form.clause.clauseSureties.length; b++) {
            if (this.form.clause.clauseSureties[b].payDateType == 0) {
              this.form.clause.clauseSureties[b].payDate = null
            }
            this.form.clause.clauseSureties[b].type =
              this.form.clause.clauseSureties[b].type + ''
            this.form.clause.clauseSureties[b].payDateType =
              this.form.clause.clauseSureties[b].payDateType + ''
          }
          //优惠条款
          for (let d = 0; d < this.form.clause.clauseDiscounts.length; d++) {
            this.form.clause.clauseDiscounts[d].type =
              this.form.clause.clauseDiscounts[d].type + ''
            this.form.clause.clauseDiscounts[d].discountType =
              this.form.clause.clauseDiscounts[d].discountType + ''
            this.form.clause.clauseDiscounts[d].discount =
              aaa.clauseDiscounts[d].discount
            this.form.clause.clauseDiscounts[d].discountTerm =
              aaa.clauseDiscounts[d].discountTerm
            this.form.clause.clauseDiscounts[d].discountDuration =
              aaa.clauseDiscounts[d].discountDuration
            this.form.clause.clauseDiscounts[d].note =
              aaa.clauseDiscounts[d].note
          }
          if (this.form.clause.clauseProperty.termType == null) {
            this.form.clause.clauseProperty.termType = '0'
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    a(e) {
      console.log(e, 1)
    },
    async getSignendid(id) {
      try {
        const res = await http.getSignId(id)
        const { success, data } = res.data
        if (success) {
          data.sign.clause.clauseTerm.termDurations.forEach(item => {
            const stored = []
            item.stores.forEach(it => {
              stored.push(it.storeId)
            })
            item.store = stored
          })
          this.form = data.sign
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async getSignchangeterms(id) {
      try {
        const res = await http.getSignchangeterms(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.sign.clause.customerBrandId == null) {
            fetch('', brand => (this.datalist = brand))
          } else {
            if (data.sign.clause.customerBrandId != '') {
              this.getNativelistID(data.sign.clause.customerBrandId)
            }
          }
          var aaa = data.sign.clause
          aaa.clauseProperty.termType = aaa.clauseProperty.termType + ''
          if (aaa.clauseProperty.propertyFeeType == null) {
            aaa.clauseProperty.propertyFeeType = '5'
            aaa.clauseProperty.commonFeeType = '5'
            aaa.clauseProperty.decorateFeeType = '5'
            aaa.clauseProperty.adFeeType = '3'
            aaa.clauseProperty.publicFeeType = '3'
            aaa.clauseProperty.rubbishFeeType = '4'
          } else {
            aaa.clauseProperty.propertyFeeType =
              aaa.clauseProperty.propertyFeeType + ''
            aaa.clauseProperty.commonFeeType =
              aaa.clauseProperty.commonFeeType + ''
            aaa.clauseProperty.decorateFeeType =
              aaa.clauseProperty.decorateFeeType + ''
            aaa.clauseProperty.adFeeType = aaa.clauseProperty.adFeeType + ''
            aaa.clauseProperty.publicFeeType =
              aaa.clauseProperty.publicFeeType + ''
            aaa.clauseProperty.rubbishFeeType =
              aaa.clauseProperty.rubbishFeeType + ''
          }
          aaa.clauseTerm.termType = aaa.clauseTerm.termType + ''
          aaa.clauseTerm.billType = aaa.clauseTerm.billType + ''
          aaa.clauseTerm.billType2 = aaa.clauseTerm.billType2 + ''
          aaa.clauseTerm.billDateType = aaa.clauseTerm.billDateType + ''
          aaa.clauseTerm.payDateType = aaa.clauseTerm.payDateType + ''
          aaa.clauseTerm.firstPayType = aaa.clauseTerm.firstPayType + ''
          aaa.clauseTerm.zeroType = aaa.clauseTerm.zeroType + ''
          if (data.sign.clause.clauseLate == null) {
            data.sign.clause.clauseLate = {
              days: '1',
              lateType: '0',
              fee: '1',
              limit: '5',
            }
          }
          data.sign.Date = []
          data.sign.clause.clauseTerm.termDurations.forEach(it => {
            it.Date = []
          })
          this.form = data.sign
          this.getClientId(data.sign.partyBId)
          const date = []
          date.push(moment(data.sign.startDate), moment(data.sign.endDate))
          this.form.Date = date
          //租赁条款
          for (
            var i = 0;
            i < data.sign.clause.clauseTerm.termDurations.length;
            i++
          ) {
            data.sign.clause.clauseTerm.termDurations[i].type =
              data.sign.clause.clauseTerm.termDurations[i].type + ''
            const stored = []
            aaa.clauseTerm.termDurations[i].stores.forEach(item => {
              stored.push(item.storeId)
            })
            data.sign.clause.clauseTerm.termDurations[i].store = stored
            // console.log(store);
            // console.log(aaa.clauseTerm.termDurations[i].store);
            data.sign.clause.clauseTerm.termDurations[i].feeType =
              data.sign.clause.clauseTerm.termDurations[i].feeType + ''
            const da = []
            da.push(
              moment(aaa.clauseTerm.termDurations[i].startDate),
              moment(aaa.clauseTerm.termDurations[i].endDate)
            )
            this.form.clause.clauseTerm.termDurations[i].Date = da
            // console.log(this.form.clause);
          }
          //   this.onChangetime1(i);
          for (var b = 0; b < this.form.clause.clauseSureties.length; b++) {
            if (this.form.clause.clauseSureties[b].payDateType == 0) {
              this.form.clause.clauseSureties[b].payDate = null
            }
            this.form.clause.clauseSureties[b].type =
              this.form.clause.clauseSureties[b].type + ''
            this.form.clause.clauseSureties[b].payDateType =
              this.form.clause.clauseSureties[b].payDateType + ''
          }
          //优惠条款
          for (let d = 0; d < this.form.clause.clauseDiscounts.length; d++) {
            this.form.clause.clauseDiscounts[d].type =
              this.form.clause.clauseDiscounts[d].type + ''
            this.form.clause.clauseDiscounts[d].discountType =
              this.form.clause.clauseDiscounts[d].discountType + ''
            this.form.clause.clauseDiscounts[d].discount =
              aaa.clauseDiscounts[d].discount
            this.form.clause.clauseDiscounts[d].discountTerm =
              aaa.clauseDiscounts[d].discountTerm
            this.form.clause.clauseDiscounts[d].discountDuration =
              aaa.clauseDiscounts[d].discountDuration
            this.form.clause.clauseDiscounts[d].note =
              aaa.clauseDiscounts[d].note
          }
          if (this.form.clause.clauseProperty.termType == null) {
            this.form.clause.clauseProperty.termType = '0'
          }
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async putSign(form) {
      try {
        const res = await http.putSign(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.modelvisible = false
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    // 新增
    async AddSign(form) {
      try {
        const res = await http.AddSign(form)
        const { success, msg } = res.data
        if (success) {
          this.onClose()
          this.modelvisible = false
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async billSign(form) {
      try {
        const res = await http.billSign(form)
        const { success, msg, data } = res.data
        if (success) {
          this.modelvisible = true
          data.bills.forEach(item => {
            item.id = nanoid()
          })
          this.data = data.bills
          //   console.log(this.data);
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    subcao() {
      this.putsignterm()
      this.visible = false
      this.termvisible = false
    },
    async putsignterm() {
      try {
        const res = await http.putsignterm(this.form)
        const { success } = res.data
        if (success) {
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getSign()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    //提交审核
    handleauditinfo(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      this.putObject(
        3,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    handleauditinfos({ fileList }) {
      if (this.submitAttach.length > fileList.length) {
        this.submitAttach = fileList
      }
    },
    onchangestate(e, i) {
      this.state = i
      this.auditvisible = true
      this.linkId = e.id
    },
    onauditsub() {
      const sub = JSON.stringify(this.submitAttach)
      if (this.state == 0) {
        this.signsubmit(sub)
      } else if (this.state == 1) {
        // 作废
        this.getsigndisuse(sub)
      } else if (this.state == 2) {
        // 变更主体
        this.getSignchange1(sub)
        // if (this.clinttype == 1) {
        //   this.getSignchange1(sub, {
        //     partyAId: this.form.partyAId,
        //     partyAName: this.form.partyAName,
        //     partyAContact: this.form.partyAContact,
        //     partyAAddress: this.form.partyAAddress,
        //     partyBIdP: this.form.partyBIdP,
        //     partyBNameP: this.form.partyBNameP,
        //     partyBGenderP: this.form.partyBGenderP,
        //     partyBContact: this.form.partyBContact,
        //     partyBAddress: this.form.partyBAddress,
        //   });
        // } else {
        //   this.getSignchange1(sub, {
        //     partyAId: this.form.partyAId,
        //     partyAName: this.form.partyAName,
        //     partyAContact: this.form.partyAContact,
        //     partyAAddress: this.form.partyAAddress,
        //     partyBCerditE: this.form.partyBCerditE,
        //     partyBNameE: this.form.partyBNameE,
        //     partyBPeopleE: this.form.partyBPeopleE,
        //     partyBContact: this.form.partyBContact,
        //     partyBAddress: this.form.partyBAddress,
        //   });
        // }
      } else if (this.state == 3) {
        // 变更条款
        this.getSignchange2(sub)
      } else if (this.state == 4) {
        // 变更条款
        this.signcancel(sub)
      } else if (this.state == 5) {
        // 变更条款
        this.getSignstateback(sub)
      }
    },
    onauditcancel() {
      this.linkId = ''
      this.submitNote = ''
      this.submitAttach = []
      this.auditvisible = false
    },
    async signsubmit(submitAttach) {
      try {
        const res = await http.signsubmit({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async signcancel(submitAttach) {
      try {
        const res = await http.signcancel({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async getSignstateback(submitAttach) {
      try {
        const res = await http.getSignstateback({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },

    //Change事件
    buildChange(t, i) {
      this.isbuild = i
      this.floorid(t.id)
      this.storelist = []
      this.stores = []
    },
    floorChange(t, i) {
      this.isfloor = i
      this.floors = t.id
      this.storetype(t.id, 0)
      this.stores = []
    },
    storeChange(t, i) {
      //   console.log(t.id);
      this.getStoreinfo(t.id)
      this.isstore = i
    },
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.projectlist = data.projects
          this.projectlist.forEach(i => {
            if (
              JSON.parse(localStorage.getItem('store-userlist')).projectInfo
                .projectId == i.id
            ) {
              this.isproject = 0
              return
            }
          })
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
          if (data.buildings.length == 0) {
            this.floorlist = []
          } else {
            this.floorid(this.buildlist[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          this.storetype(data.floors[0].id, 0)
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async storetype(floorId, type) {
      try {
        const res = await http.storetype(floorId, type)
        const { success, data } = res.data
        if (success) {
          this.storelist = data.stores
          for (let a = 0; a < this.form.stores.length; a++) {
            this.storelist.forEach((i, index) => {
              if (i.name == this.form.stores[a].storeNameSnapshot) {
                this.storelist[index].rentState = 1
              }
            })
          }
          if (data.stores.length > 0) {
            let st = false
            data.stores.forEach((item, index) => {
              if (item.state == 0 && item.rentState == 0) {
                if (st == false) {
                  this.isstore = index
                  st = true
                }
              }
            })
            this.getStoreinfo(data.stores[this.isstore].id)
          } else {
            this.getStoreinfo(data.stores[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onchecked(id) {
      //   console.log(id);
      const index = this.checkStore.findIndex(item => item == id)
      if (index == -1) {
        this.checkstorelist(id)
      } else {
        this.checkStores.splice(index, 1)
      }
    },
    async checkstorelist(id) {
      try {
        const res = await http.getStoreinfo(id)
        const { success, data } = res.data
        if (success) {
          this.checkStores.push({
            storeId: data.store.id,
            storeNameSnapshot: data.store.name,
            floorNameSnapshot: data.store.floorName,
            buildingNameSnapshot: data.store.buildingName,
            totalAreaSnapshot: data.store.totalArea,
            useAreaSnapshot: data.store.useArea,
            rentAreaSnapshot: data.store.rentArea,
          })
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //甲方
    async getenterpriselist() {
      try {
        const res = await http.getenterpriselist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Enterpriselist = data.enterprises
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //乙方
    async getClientlist() {
      try {
        const res = await http.getClientlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Clientlist = data.clients
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async postsignterm() {
      try {
        const res = await http.postsignterm(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.signstermlist = data.temps.records
          this.total = data.temps.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getuserlistCurrent() {
      try {
        const res = await http.getuserlistCurrent()
        const { success, data } = res.data
        if (success) {
          this.userlist = data.users
          //   console.log("userlist", data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getsignterm(id) {
      try {
        const res = await http.getsignterm(id)
        const { success, data } = res.data
        if (success) {
          var dea = JSON.parse(data.temp.temp)
          this.form = dea
          this.getClientId(this.form.partyBId)
          if (dea.startDate == '' || dea.endDate == []) {
            this.form.Date = []
          } else {
            const date = []
            date.push(moment(dea.startDate), moment(dea.endDate))
            this.form.Date = date
          }
          if (dea.clauseLate == null) {
            this.form.clause.clauseLate = {
              days: '1',
              lateType: '0',
              fee: '1',
              limit: '5',
            }
          }
          if (
            dea.clause.customerBrandId == null ||
            dea.clause.customerBrandId == ''
          ) {
            fetch('', brand => (this.datalist = brand))
          } else {
            this.getNativelistID(dea.clause.customerBrandId)
          }

          this.form.tempId = data.temp.id
          this.form.name = data.temp.name
          //   console.log(dea);
          //   console.log(dea.projectId);
          this.form.agreement = dea.agreement
          this.form.files = dea.files
          this.form.stores = dea.stores
          var aaa = this.form.clause
          if (aaa.clauseProperty.propertyFeeType == null) {
            aaa.clauseProperty.propertyFeeType = '5'
            aaa.clauseProperty.commonFeeType = '5'
            aaa.clauseProperty.decorateFeeType = '5'
            aaa.clauseProperty.adFeeType = '3'
            aaa.clauseProperty.publicFeeType = '3'
            aaa.clauseProperty.rubbishFeeType = '4'
          } else {
            aaa.clauseProperty.propertyFeeType =
              aaa.clauseProperty.propertyFeeType + ''
            aaa.clauseProperty.commonFeeType =
              aaa.clauseProperty.commonFeeType + ''
            aaa.clauseProperty.decorateFeeType =
              aaa.clauseProperty.decorateFeeType + ''
            aaa.clauseProperty.adFeeType = aaa.clauseProperty.adFeeType + ''
            aaa.clauseProperty.publicFeeType =
              aaa.clauseProperty.publicFeeType + ''
            aaa.clauseProperty.rubbishFeeType =
              aaa.clauseProperty.rubbishFeeType + ''
          }
          aaa.clauseTerm.termType = aaa.clauseTerm.termType + ''
          aaa.clauseTerm.billType = aaa.clauseTerm.billType + ''
          aaa.clauseTerm.billType2 = aaa.clauseTerm.billType2 + ''
          aaa.clauseTerm.billDateType = aaa.clauseTerm.billDateType + ''
          aaa.clauseTerm.payDateType = aaa.clauseTerm.payDateType + ''
          aaa.clauseTerm.firstPayType = aaa.clauseTerm.firstPayType + ''
          aaa.clauseTerm.zeroType = aaa.clauseTerm.zeroType + ''

          for (var b = 0; b < this.form.clause.clauseSureties.length; b++) {
            if (this.form.clause.clauseSureties[b].payDateType == 0) {
              this.form.clause.clauseSureties[b].payDate = null
            }
            this.form.clause.clauseSureties[b].type =
              this.form.clause.clauseSureties[b].type + ''
            this.form.clause.clauseSureties[b].payDateType =
              this.form.clause.clauseSureties[b].payDateType + ''
          }
          for (let d = 0; d < this.form.clause.clauseDiscounts.length; d++) {
            this.form.clause.clauseDiscounts[d].type =
              this.form.clause.clauseDiscounts[d].type + ''
            this.form.clause.clauseDiscounts[d].discountType =
              this.form.clause.clauseDiscounts[d].discountType + ''
            this.form.clause.clauseDiscounts[d].discount =
              aaa.clauseDiscounts[d].discount
            this.form.clause.clauseDiscounts[d].discountTerm =
              aaa.clauseDiscounts[d].discountTerm
            this.form.clause.clauseDiscounts[d].discountDuration =
              aaa.clauseDiscounts[d].discountDuration
            this.form.clause.clauseDiscounts[d].note =
              aaa.clauseDiscounts[d].note
            this.$nextTick(() => {
              this.fom.setFieldsValue({
                Ddiscount: this.form.clause.clauseDiscounts[d].discount,
                DdiscountTerm: this.form.clause.clauseDiscounts[d].discountTerm,
                DdiscountDuration:
                  dea.clause.clauseDiscounts[d].discountDuration,
                Dnote: this.form.clause.clauseDiscounts[d].note,
              })
            })
          }
          for (
            var i = 0;
            i < this.form.clause.clauseTerm.termDurations.length;
            i++
          ) {
            if (
              aaa.clauseTerm.termDurations[i].startDate == '' ||
              aaa.clauseTerm.termDurations[i].endDate == []
            ) {
              this.form.clause.clauseTerm.termDurations[i].Date = []
            } else {
              const da = []
              da.push(
                moment(aaa.clauseTerm.termDurations[i].startDate),
                moment(aaa.clauseTerm.termDurations[i].endDate)
              )
              this.form.clause.clauseTerm.termDurations[i].Date = da
              //   this.onChangetime1(i);
            }
            this.form.clause.clauseTerm.termDurations[i].type =
              this.form.clause.clauseTerm.termDurations[i].type + ''
            var store = []
            aaa.clauseTerm.termDurations[i].stores.forEach(item => {
              store.push(item.storeId)
            })
            this.form.clause.clauseTerm.termDurations[i].store = store
            this.form.clause.clauseTerm.termDurations[i].feeType =
              this.form.clause.clauseTerm.termDurations[i].feeType + ''
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async BusinessTypelist() {
      try {
        const res = await http.BusinessTypelist()
        const { success, data } = res.data
        if (success) {
          data.businessTypes.forEach(i => {
            if (i.children.length > 0) {
              i.children.forEach(i => {
                if (i.children.length > 0) {
                  i.children.forEach(i => {
                    if (i.children.length > 0) {
                      i.children.forEach(() => {
                        // console.log(i);
                        this.$message.error('业态底层')
                      })
                    } else {
                      i.children = undefined
                    }
                  })
                } else {
                  i.children = undefined
                }
              })
            } else {
              i.children = undefined
            }
          })
          this.BusinessTypes = data.businessTypes
          //   console.log(data.businessTypes);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    ontemp(e) {
      this.form.id = e
      this.gettemplatelist()
    },
    //确定生成模板
    handletempOk() {
      setTimeout(() => {
        this.tempvisible = false
      }, 100)
      //   this.getintentiongenerate(this.templateId, this.form.id);
      window.open(
        `${api}business/sign/generate/${this.templateId}/${this.form.id}`
      )
    },
    //取消
    handletempCancel() {
      this.tempvisible = false
      this.templateId = ''
      this.templist = []
      this.form.id = ''
    },
    async gettemplatelist() {
      try {
        const res = await http.gettemplatelist(1)
        const { success, data } = res.data
        if (success) {
          this.templist = data.templates
          this.templateId = data.templates[0].id
          this.tempvisible = true
        }
      } catch (ex) {
        this.$message.warning(ex)
        console.log('error:', ex)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    this.type()
    this.getSign()
    this.getsts()
    this.getuserlistCurrent()
    this.BusinessTypelist()
    this.getIntentionlist()
    this.permissionlist = JSON.parse(
      localStorage.getItem('store-userlist')
    ).permissions
    var users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    this.projectName = users.projectInfo.projectName
    this.buildingid(this.form.projectId)
    this.form.belongTo = users.id + ''
    this.form.signBy = users.id + ''
    if (users == null) {
      this.logvisible = true
    }
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    count() {
      return this.expand ? 8 : 5
    },
    checkAll: {
      get() {
        return this.checkStore.length === this.storelist.length
      },
      set(val) {
        if (val) {
          this.checkStore = []
          this.checkStores = []
          this.storelist.forEach(storelist => {
            if (storelist.state == 1 || storelist.rentState == 1) {
              console.log()
            } else {
              this.checkStore.push(storelist.id)
              this.checkstorelist(storelist.id)
            }
          })
        } else {
          this.checkStore = []
          this.checkStores = []
        }
      },
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log(selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 670px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.text-store {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-btn {
  margin: 0px 8px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.deletestore {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: -2px;
  z-index: 10;
}
.deletestore:hover {
  cursor: pointer;
  color: rgb(197, 172, 29);
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.allcheck {
  position: absolute;
  top: -13px;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 50px;
  border-radius: 0;
  padding-left: 7px;
}
.content-body {
  padding: 24px;
  .ant-form-item {
    height: 90px;
    margin: 0;
    padding-right: 20px;
  }
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
.form-item {
  ::v-deep .ant-form-item-label {
    text-align: left;
    line-height: 23px;
  }
}
.ant--input {
  width: 25%;
}
.terms-input {
  width: 17%;
}
.content-body {
  padding: 24px;
  .body-building {
    line-height: 65px;
    min-width: 65px;
    min-height: 65px;
    text-align: center;
    border: 1px dashed #999;
    position: relative;
  }
  .body-building:hover {
    cursor: pointer;
    color: rgb(197, 172, 29);
    border: 1px dashed rgb(197, 172, 29);
  }
}
// .onclose() {
//   position: absolute;
//   top: 3px;
//   right: 3px;
// }
input {
  border-radius: 0;
}
.terms-option {
  width: 65px;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  right: 47px;
  cursor: pointer;
}
.div-div {
  width: 50%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 4px;
  border: 1px solid #d9d9d9;
}
.div-div:hover {
  border: 1px solid orange;
}
.div-hover {
  width: 50%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 4px;
  border: 1px solid orange;
}
.yiban {
  background: #fff;
  margin-top: 24px;
}
.ant-select-enabled {
  width: 100px;
}
.aaa ::v-deep .ant-select-selection--single {
  border-radius: 0;
  width: 100px;
}
.aaa ::v-deep .ant-input {
  border-left: none;
  border-right: none;
}
.aaa ::v-deep .ant-input-number {
  border-left: none;
  border-right: none;
}
::v-deep .ant-input-number {
  border-radius: 0;
}
.ccc ::v-deep .ant-input {
  border-right: none;
}
.ccc ::v-deep .ant-select-selection--single {
  border-radius: 0;
  width: 100px;
}
.bbb ::v-deep .ant-select-selection--single {
  border-radius: 0;
  width: 100px;
  border-right: none;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-input {
  border-radius: 0;
}
.search-wrop-left {
  height: 450px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  .search-left-project,
  .search-left-build,
  .search-left-floor {
    border-right: 1px solid #d9d9d9;
    min-width: 130px;
    width: 25%;
    overflow-y: auto;
    height: 100%;
  }
  .search-bottom {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
  }
  .search-bottom:hover {
    background-color: #f5f5f5;
  }
  .search-left-top {
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #f0f1f4;
  }
  .search-left-stoor {
    min-width: 130px;
    width: 25%;
    height: 100%;
    overflow-y: auto;
  }
}
.content-body .price-formitem {
  position: relative;
}
.price-formitem {
  position: relative;
  width: 50%;
}
.back-search {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f5f5f5;
}
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
.input-checkBox {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.form-item {
  .ant-form-item {
    height: 60px;
    margin-bottom: 0;
  }
}
.flex-ju {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.save-draw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 50px;
  margin-bottom: 1px;
  line-height: 50px;
  border-top: 1px solid #eaeaea;
}
::v-deep .ant-select-selection--multiple {
  border-radius: 0;
}
/deep/.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 5px;
  margin-bottom: -3px;
  height: auto;
  max-height: 30px;
  max-width: 100%-20px;
  overflow: auto;
  overflow-y: hidden;
}
/deep/.ant-select-selection--multiple .ant-select-selection__choice {
  overflow: initial;
}
// 调整内部样式
/deep/.ant-select ul,
.ant-select ol {
  display: flex;
}
/deep/.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  margin-top: 3px;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  width: auto;
  max-height: 200px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
//会导致品牌不能搜索
// /deep/.ant-select-search--inline .ant-select-search__field__wrap {
//   max-width: 0px !important;
// }
/deep/.ant-select-selection__rendered::-webkit-scrollbar {
  /*滚动条整体样式*/
  height: 5px;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: lightskyblue;
}
/deep/.ant-select-selection__rendered::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
}
</style>
